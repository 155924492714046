/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect,useState } from 'react'
import { useDispatch,useSelector} from "react-redux";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';


import {setCatalogResFilt,setActionToDo} from '../../../../redux/portal';
import {setModalsProps,setButtonOkLoad } from '../../../../redux/modals';
import {setCollection, setJoinPayors, setPeriod, 
        setWithData,setConcat, setFilterOption } from '../../../../redux/filter';

import SelectLineComponent from '../SelectComps'
import { getMulValues, getValue, readToshort, shortToRead, simpleFirstSelect } from '../../../../Data/Variables';


const JoinPayorsTab = ({filterValues}) =>
{
    const { theCatalog } = useSelector((state) => state.portal);
    const { joinPayors } = useSelector((state) => state.filter);
    const dispatch = useDispatch();
    const animatedComponents = makeAnimated();

    return(
        <div className="field box">   
        <h1 className='subtitle is-5 m-0'>Join Payors :</h1>
        <div className='m-2'><b>Filter</b></div> 
        <Select options={getMulValues(filterValues,[theCatalog,"Payor"],[]).map((val) => {return {value : val,label : val}})}         
                menuPortalTarget={document.body} 
                onChange={(e) => {
                    let ary = [...joinPayors];
                    ary[0] = e.map((e) => e.value)
                    dispatch(setJoinPayors(ary))
                }}
                value={joinPayors[0].map((val)=> {return {"value" : val,"label":val}})}
                styles={{ menuPortal: base => ({ ...base, zIndex: 60}) }}
                isMulti components={animatedComponents}  closeMenuOnSelect={false} placeholder={"Select Payor..."}/>
        <div className='m-2'><b>According To</b></div>
        <Select options={getMulValues(filterValues,[theCatalog,"Payor"],[]).map((val) => {return {value : val,label : val}})}         
            menuPortalTarget={document.body} 
            onChange={(e) => {
                let ary = [...joinPayors];
                ary[1] = e.map((e) => e.value)
                dispatch(setJoinPayors(ary))
            }}
            value={joinPayors[1].map((val)=> {return {"value" : val,"label":val}})}
            styles={{ menuPortal: base => ({ ...base, zIndex: 60 }) }}
            isMulti components={animatedComponents}  closeMenuOnSelect={false} placeholder={"Select Payor..."}/>
        <div className='m-3'></div>
        <Select options={getMulValues(filterValues,[theCatalog,"Contract"],[]).map((val) => {return {value : val,label : val}})}         
            menuPortalTarget={document.body} 
            onChange={(e) => {
                let ary = [...joinPayors];
                ary[2] = e.map((e) => e.value)
                dispatch(setJoinPayors(ary))
            }}
            value={joinPayors[2].map((val)=> {return {"value" : val,"label":val}})}
            styles={{ menuPortal: base => ({ ...base, zIndex: 60 }) }}
            isMulti components={animatedComponents}  closeMenuOnSelect={false} placeholder={"Select Accounts..."}/>
        <div className='m-2'><b>By</b></div> 
        <Select options={simpleFirstSelect}
                menuPortalTarget={document.body} 
                onChange={(e) => {
                    let ary = [...joinPayors];
                    ary[3] = e.map((e) => e.value)
                    dispatch(setJoinPayors(ary))
                }}
                value={joinPayors[3].map((val)=> {return {"value" : val,"label":val}})}
                styles={{ menuPortal: base => ({ ...base, zIndex: 60 }) }}
                isMulti components={animatedComponents}  closeMenuOnSelect={false} placeholder={"Select Column..."}/>
        <article className="message mt-4">
            <div className="message-body">
                <b>Description:</b><br/>
                This filter will Join both Payors, According to the Accounts<br/>
                And Will display the column of the specific account, In the other Payor
            </div>
        </article>

    </div> );
}
const CollectionTab = ({filterValues,filterTab}) =>
{
    const { theCatalog } = useSelector((state) => state.portal);
    const { collection } = useSelector((state) => state.filter);

    const dispatch = useDispatch();
    const animatedComponents = makeAnimated();
    const [collectionTitle,setCollectionTitle] = useState("Song");

    return (
    <div className="field box" style={filterTab !== 2 ? {"display" : "none"} : {}}>   
        <div className='column is-narrow'>
            <h1 className='subtitle is-5'>Collections</h1>
            <Select options={simpleFirstSelect} menuPortalTarget={document.body} components={animatedComponents}
                        onChange={(e) => setCollectionTitle(getValue(readToshort,e.value,e.value))} value={{"value" : collectionTitle,"label" : getValue(shortToRead,collectionTitle,collectionTitle)}}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 60}) }}/>

            <div className='m-2'></div>
            
            <Select options={getMulValues(filterValues,[theCatalog,collectionTitle],[]).map((val) => {return {value : val,label : val}})}
                        menuPortalTarget={document.body} components={animatedComponents}
                        onChange={(e) => dispatch(setCollection({[collectionTitle] : e.map((val) => val.value)}))} value={getValue(collection,collectionTitle,[]).map((val) => {return {"value" : val,"label":val}})}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 60}) }} isMulti closeMenuOnSelect={false}
                        />
        
            <article className="message mt-4">
                <div className="message-body">
                    <b>Description:</b><br/>
                    This filter will make a Collection called "Rest of X"
                    from everything that you didn't choose that X is the Title that you chose.
                </div>
            </article>
        </div>
    </div>)
}
const FilterTab = ({filters,setFiltersCount,filterTab,filtersCount,setFilters,filterValues,jwt}) =>
{

    const { theCatalog,catalogResFilt} = useSelector((state) => state.portal);
    const { withData,filterOption } = useSelector((state) => state.filter);

    const dispatch = useDispatch();
    return(             
        <>
            <div style={filterTab !== 0 ? {"display" : "none"} : {}}>
                <div className='box mb-2'>
                    <center><p className='mr-5'>Click + To add new Filter:</p></center>
                    <div className='columns'>
                        <div className='column'>
                            <label className='mr-1' htmlFor="switchExample">Exclude Data </label>
                            <input id="switchExample" type="checkbox" name="switchExample" className="switch" checked={withData} onChange={() => dispatch(setWithData(!withData))}/>
                            <label htmlFor="switchExample">Include Data</label>
                        </div>
                        <div className='column'>
                            <label className='mr-2' htmlFor="filteroption">For User</label>
                            <input id="filteroption" type="checkbox" name="filteroption" className="switch is-link" checked={filterOption} onChange={() => dispatch(setFilterOption(!filterOption))}/>
                            <label htmlFor="filteroption">For Client</label>
                        </div>
                    </div>
                </div>
                <article className="message mt-4">
                    <div className="message-body">
                        <b>Description:</b><br/>
                        This filter will Exclude or Include Data<br/>
                        According to the filters below.
                    </div>
                </article>
                {Object.values(filters)}
                <button className='button is-info is-outlined' style={{"width" : "100%"}} onClick={() => 
                {
                    let ary = [...catalogResFilt[theCatalog]];
                    ary.push({});
                    dispatch(setCatalogResFilt({...catalogResFilt,[theCatalog] : ary}));
                    setFilters({...filters,[theCatalog] : [...filters[theCatalog],<React.Fragment key={filtersCount[theCatalog]}><SelectLineComponent filterValues={filterValues[theCatalog]} readToshort={readToshort} jwt={jwt} index={filtersCount[theCatalog]} catalog={theCatalog}/></React.Fragment>]})
                    setFiltersCount({...filtersCount,[theCatalog]: filtersCount[theCatalog]+1});
                }}>+</button>
            </div>
        </>)

}

const ConcatTab = ({filterValues,filterTab,validCatalogs,catalogMenuList}) =>
{
    const { theCatalog } = useSelector((state) => state.portal);
    const dispatch = useDispatch();
    const animatedComponents = makeAnimated();
    const { concat } = useSelector((state) => state.filter);
    const [options,] = useState(Object.keys(validCatalogs).map((e) => {return {"value" : validCatalogs[e],"label" : e}}));

    return(
        <div>
            <div className="field box">   
            <div className='m-2'><b>Concatenate Filter</b></div> 
            
            <Select options={options}         
                menuPortalTarget={document.body} 
                onChange={(e) => {e == null || e.length === 0 ? dispatch(setConcat([options.find((val) => val.value === catalogMenuList[theCatalog]["id"])])) : dispatch(setConcat(e))}}
                value={concat}              
                styles={{ menuPortal: base => ({ ...base, zIndex: 60}) }}
                components={animatedComponents} placeholder={"Select Catalogs"} isMulti closeMenuOnSelect={false} 
                />

            <article className="message mt-4">
                <div className="message-body">
                    <b>Description:</b><br/>
                    This filter will Concatenate between Catalogs <br/>
                </div>
            </article>
            </div>
         </div> );
}
const PeriodTab = ({filterValues,filterTab}) =>
{
    const { theCatalog } = useSelector((state) => state.portal);
    const { period } = useSelector((state) => state.filter);
    const dispatch = useDispatch();
    const animatedComponents = makeAnimated();

    return(
        <div style={filterTab !== 3 ? {"display" : "none"} : {}}>
            <div className="field box">   
            <div className='m-2'><b>Filter</b></div> 
            <Select options={getMulValues(filterValues,[theCatalog,"Period"],[]).map((val) => {return {value : val,label : val}})}         
                    menuPortalTarget={document.body} 
                    onChange={(e) => {e == null ? dispatch(setPeriod("")) : dispatch(setPeriod(e.value))}}
                    value={{"value" : period,"label":period}}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 60}) }}
                    components={animatedComponents} placeholder={"Select Period"}  isClearable
                    />
            <article className="message mt-4">
                <div className="message-body">
                    <b>Description:</b><br/>
                    This filter will Filter the table by Period<br/>
                </div>
            </article>
            </div>
         </div> );
}

const ModalFilter = ({filterValues,jwt,validCatalogs,catalogMenuList}) =>
{
    const { theCatalog ,actionToDo,catalogResFilt} = useSelector((state) => state.portal);
    const { modalsProps,buttonOkLoad } = useSelector((state) => state.modals);
    const { filterCustom,joinPayors,collection,period,concat } = useSelector((state) => state.filter);
    const [filtersCount,setFiltersCount] = useState({});
    const [filters,setFilters] = useState({});
    const [filterTab,setFilterTab] = useState(0);
    const dispatch = useDispatch();

    let colValues = Object.values(collection);
    let collectionFilter = colValues.length > 0 && colValues[0].length > 0;
    let joinFilter = (joinPayors.every((val) => val.length > 0));
    let regFilter = ((!getValue(catalogResFilt,theCatalog,[]).every(element => {return  element !== undefined ? Object.keys(element).length === 0 || Object.values(element).length === 0: true})));
    let periodFilter = period !== "";
    let concatFilter = concat.length > 1;

    const clearFilters = () =>
    {
        if(filterTab === 0)
        {
            dispatch(setCatalogResFilt({...catalogResFilt,[theCatalog]: []}));
            setFilters({...filters,[theCatalog] : []});
            setFiltersCount({...filtersCount,[theCatalog] : 0});
        }
        else if(filterTab === 1)
            dispatch(setJoinPayors([[],[],[],[]]));
        else if(filterTab === 2)
            dispatch(setCollection({}));
        else if(filterTab === 3)
            dispatch(setPeriod(""));
        else if(filterTab === 4)
            dispatch(setConcat([{"value" : catalogMenuList[theCatalog]["id"],"label" : theCatalog}]));
    }
    useEffect(() => 
    {
        if(theCatalog !== undefined && theCatalog !== "")
            dispatch(setConcat([{"value" : catalogMenuList[theCatalog]["id"],"label" : theCatalog}]));

        if(!(theCatalog in catalogResFilt))
        {
            let ary = {...catalogResFilt};
            ary[theCatalog] = [];
            dispatch(setCatalogResFilt(ary));
        }
        if(!(theCatalog in filters))
            setFilters({...filters,[theCatalog] : []});
        if(!(theCatalog in filtersCount))
            setFiltersCount({...filtersCount,[theCatalog] : 0});
        
    },[theCatalog])
    useEffect(() =>
    {
        if(actionToDo.startsWith("delComp"))
        {
            dispatch(setActionToDo(""));
            let ary = [...catalogResFilt[theCatalog]];
            ary[parseInt(actionToDo.slice(-1))] = undefined;
            dispatch(setCatalogResFilt({...catalogResFilt,[theCatalog]:ary}));

            if(catalogResFilt[theCatalog].every(element => {return  element === undefined}))  
                clearFilters();
            else
            {
                ary = [...filters[theCatalog]];
                ary[parseInt(actionToDo.slice(-1))] = undefined;
                setFilters({...filters,[theCatalog] : ary});
            }
            
        }
    },[actionToDo])

    return(
    <center>
    <div className={modalsProps["modalFilter"] ? "modal is-active" : "modal"} style={filterCustom ? {"zIndex" : 35,"position" : "absolute","width" : "50%","left" : "44%"} : {"zIndex" : 35}}>
        {!filterCustom ? <div className="modal-background"></div> : <></>}
        <div className="modal-card" style={filterCustom ? {"width":"100%","height":"92%"} : {"width":"77%","height":"92%"} }>
            <header className="modal-card-head">
                <p className="modal-card-title">Filters</p>
                {!filterCustom ? <button className="delete" onClick={()=>{dispatch(setModalsProps({...modalsProps,"modalFilter" : false}));setFilterTab(0)}}>{/*Cancel*/}</button>:<></>}
            </header>
            <section className="modal-card-body fullHeight">
                <div className='box'>
                    <>
                        <div className="tabs is-centered is-boxed is-fullwidth">
                            <ul>
                                <li className={(filterTab === 0 ? "is-active" : "") + " is-warning"} onClick={() => setFilterTab(0)}>
                                    <a>
                                        <span className="icon is-small"><i className="fa-solid fa-filter"/></span>
                                        <span>Regular Filter</span>
                                        {regFilter ? <span className='dotWarn has-background-warning'><i className="fa-solid fa-exclamation"/></span> : null}
                                    </a>
                                </li>
                                <li className={(filterTab === 1 ? "is-active" : "") + " is-warning"} onClick={() => setFilterTab(1)}>
                                    <a>
                                        <span className="icon is-small"><i className="fa-solid fa-diagram-project" /></span>
                                        <span>Join Payors</span>
                                        {joinFilter ? <span className='dotWarn has-background-warning'><i className="fa-solid fa-exclamation"/></span> : null}
                                    </a>
                                </li>
                                <li className={(filterTab === 2 ? "is-active" : "") + " is-warning"} onClick={() => setFilterTab(2)}>
                                    <a>
                                        <span className="icon is-small"><i className="fa-solid fa-folder" /></span>
                                        <span>Collections</span>
                                        {collectionFilter ? <span className='dotWarn has-background-warning'><i className="fa-solid fa-exclamation"/></span> : null}
                                    </a>
                                </li>
                                <li className={(filterTab === 3 ? "is-active" : "") + " is-warning"} onClick={() => setFilterTab(3)}>
                                    <a>
                                        <span className="icon is-small"><i className="fa-regular fa-calendar" /></span>
                                        <span>Period Filter</span>
                                        {periodFilter ? <span className='dotWarn has-background-warning'><i className="fa-solid fa-exclamation"/></span> : null}
                                    </a>
                                </li>
                                <li className={(filterTab === 4 ? "is-active" : "") + " is-warning"} onClick={() => setFilterTab(4)}>
                                    <a>
                                        <span className="icon is-small"><i className="fa-solid fa-link"/></span>
                                        <span>Concatenate Filter</span>
                                        {concatFilter ? <span className='dotWarn has-background-warning'><i className="fa-solid fa-exclamation"/></span> : null}
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <FilterTab filters={filters} setFiltersCount={setFiltersCount} filterTab={filterTab} jwt={jwt}
                            filtersCount={filtersCount} setFilters={setFilters} filterValues={filterValues} />     
                       
                        {filterTab === 1 ? <JoinPayorsTab filterValues={filterValues}/> : null}
                        
                        <CollectionTab  filterValues={filterValues} filterTab={filterTab}/>
                        <PeriodTab filterValues={filterValues} filterTab={filterTab}/>
                        {filterTab === 4 ?
                            <ConcatTab filterValues={filterValues} filterTab={filterTab} validCatalogs={validCatalogs} catalogMenuList={catalogMenuList}/>
                        : <></>}
                    </>
                </div>
            </section>
    
            {!filterCustom ?
            <footer className="modal-card-foot buttons is-centered">
                {//dispatch(setCatalogResFilt({...catalogResFilt,[theCatalog] : catalogResFilt[theCatalog].map(element => {return {}})}));
                }
                <div className='fullwidth'>
                    <div className='is-pulled-left'>
                        <button className="button is-info" onClick={()=>{clearFilters();}}>Clear Filter</button>
                    </div>
                    <div className='is-pulled-right'>
                
                        <button className="button" onClick={()=>{dispatch(setModalsProps({...modalsProps,"modalFilter" : false}));setFilterTab(0)}}>Cancel</button>
                        <button className={buttonOkLoad["GetOutput"] ? "button is-success is-loading" : "button is-success"} onClick={() => {
                            if(joinFilter || regFilter || collectionFilter || periodFilter || concatFilter)  
                                dispatch(setModalsProps({...modalsProps,"modalGenerate" : "true"}))
                            else if(joinPayors.some((val) => val.length > 0))
                                alert("Please fill/unfill all of fields on Join Payors")
                            else 
                            { 
                                dispatch(setActionToDo("GetOutput"));
                                dispatch(setButtonOkLoad({...buttonOkLoad,"GetOutput" : true}));     
                            }}}>Save changes
                        </button>
                    </div>
                </div>
            </footer>
            :
            <footer className="modal-card-foot buttons is-centered">
                <div className='is-pulled-left'>
                    <button className="button is-info" onClick={()=>{clearFilters();}}>Clear Filters</button>
                </div>
            </footer>}
        </div>
    </div>
    </center>);
}
export default ModalFilter;