/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";

import { setModalsProps } from "../../../../redux/modals";
import { setActionToDo,setRenameOutput } from "../../../../redux/portal";
import { getMulValues, getTabDetails, titledict } from "../../../../Data/Variables";

const ModalRenameComp = () =>
{
    const { tempOutput,tabMenuList,theCatalog} = useSelector((state) => state.portal);
    const { modalsProps } = useSelector((state) => state.modals);
    const [renameOutputModal,setRenameOutputModal] = useState("");
    const [filterTable,setFilterTable] = useState([]);
    const [isInclude,setIsInclude] = useState(false);
    const {OrigRenme,tabtofolderdraft} = useSelector((state) => state.globalVars);

    useEffect(() =>
    {
        if(tempOutput !== "") 
        {   
            setRenameOutputModal(tempOutput);

            let filterArray = getTabDetails(tabMenuList,theCatalog,tempOutput,"filter",tabtofolderdraft,OrigRenme[theCatalog]);
            let JoinPayor = getTabDetails(tabMenuList,theCatalog,tempOutput,"joinPayors",tabtofolderdraft,OrigRenme[theCatalog]);
            let collectionFilter = getTabDetails(tabMenuList,theCatalog,tempOutput,"collectionFilter",tabtofolderdraft,OrigRenme[theCatalog]);
            let period = getTabDetails(tabMenuList,theCatalog,tempOutput,"Period",tabtofolderdraft,OrigRenme[theCatalog]);
            let concat = getTabDetails(tabMenuList,theCatalog,tempOutput,"concat",tabtofolderdraft,OrigRenme[theCatalog]);
            let colValues;
            if(collectionFilter !== undefined)
                colValues= Object.values(collectionFilter);
            let tables = [];
            let isInclude = false;
            if(filterArray !== undefined)
            {
                for(let filt in filterArray)
                {
                    let query = Object.keys(filterArray[filt])[0];
                    let queryAry = query.split(" or ");
                    let indexRes = 0;

                    for(let q in queryAry)
                    {
                        let tdAry = [];
                        let thAry = [];
                        let aryelements = queryAry[q].match(/(not in)|[\w@[\d\]]+/gm);
                        aryelements = aryelements.filter(e => e !== 'and'); 
                        for(let el in aryelements)
                        {
                            if(el % 3 === 0)
                                thAry.push(<React.Fragment key={thAry.length}><th>{titledict[aryelements[el]]}</th></React.Fragment>);
                            else if(el % 3 === 1)
                                isInclude = aryelements[el] === "in";
                            else if(el % 3 === 2)
                            {
                                tdAry.push(<React.Fragment key={tdAry.length}><td className="filtertd">
                                    {filterArray[filt][query][indexRes].map((aryEl,index) => 
                                        {return <React.Fragment key={index}><span className="tag ">{aryEl}</span><span>, </span></React.Fragment>})}</td></React.Fragment>);
                                indexRes++;
                            }
                        }                    
                        tables.push(
                        <React.Fragment key={q}>
                            <div className="table-container">
                                <center><h1 className="subtitle is-4 mb-2">Filter {(parseInt(q)+1)}:</h1></center>
                                <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth filtertable">
                                    <thead>               
                                        <tr>
                                            {thAry}
                                        </tr>                                
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {tdAry}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </React.Fragment>);
                    }
                }
                setIsInclude(isInclude);
            }
            if(JoinPayor !== undefined && (JoinPayor.every((val) => val.length > 0)))
            {
                tables.push(
                <React.Fragment key={tables.length}>
                    <div className='box'>
                        <h1 className="subtitle is-4">
                        <b className="mr-5">Join Payors:</b> 
                        Filter 
                        {JoinPayor[0].map((val,index) => {return <React.Fragment key={index}><span className="tag m-1 is-medium">{val}</span>, </React.Fragment>})}
                            According to 
                        {JoinPayor[1].map((val,index) => {return <React.Fragment key={index}><span className="tag m-1 is-medium">{val}</span>, </React.Fragment>})}
                        {JoinPayor[2].map((val,index) => {return <React.Fragment key={index}><span className="tag m-1 is-medium">{val}</span>, </React.Fragment>})}
                        By
                        {JoinPayor[3].map((val,index) => {return <React.Fragment key={index}><span className="tag m-1 is-medium">{val}</span>, </React.Fragment>})}

                        </h1>
                    </div> 
                </React.Fragment>);
            }
            if(collectionFilter !== undefined && colValues.length > 0 && colValues[0].length > 0)
            {
                tables.push(
                <React.Fragment key={tables.length}>
                    <div className='box'>
                        <h1 className="subtitle is-4">
                        <b className="mr-4">Collection Filter:</b>Collect everything that is not  {colValues[0].map((val,index) => {return <React.Fragment key={index}><span className="tag m-1 is-small">{val}</span>, </React.Fragment>})}
                        <br/>into "Rest Of" row
                        </h1>
                    </div> 
                </React.Fragment>);  
            }
            if(period !== undefined && period !== "")
            {
                tables.push(
                <React.Fragment key={tables.length}>
                    <div className='box'>
                        <h1 className="subtitle is-4">
                            <b>Period:</b> From <b className="has-text-danger">{period}</b> - Now
                        </h1>
                    </div> 
                </React.Fragment>);  
            }
            if(concat !== undefined &&concat.length > 1)
            {
                tables.push(
                    <React.Fragment key={tables.length}>
                    <div className='box'>
                        <h1 className="subtitle is-5">
                            This output is combined with those catalogs: {concat.map((val,index) => {return <React.Fragment key={index}><span className="tag">{val.label}</span>, </React.Fragment>})}
                        </h1>
                    </div> 
                    </React.Fragment>
                );
            }
            setFilterTable(tables);
        }
    },[tempOutput,tabMenuList])

    const dispatch = useDispatch();
    return (<>
                <div className={"modal " + (modalsProps["modalRename"] ? "is-active" : "")}>
                    <div className="modal-background"></div>
                        <div className="modal-card" style={{"width":"75%","height":"75%"}}>
                            <header className="modal-card-head">
                                <p className="modal-card-title">Details</p>
                                <button className="delete" onClick={() => {dispatch(setModalsProps({...modalsProps,"modalRename" : false}));setRenameOutputModal(tempOutput)}} />
                            </header>
                            <section className="modal-card-body">
                                {
                                    <>
                                        <h1 className="title is-4 mb-3">Change Output Name:</h1>
                                        <input className="input mb-6" type="text" value={renameOutputModal} onChange={(e) => setRenameOutputModal(e.currentTarget.value)}/>
                                    </>
                                }
                                <h1 className="title is-4 mb-3">Filter Details (This Output will {isInclude ? "include" : "exclude"} this data):</h1>
                                {filterTable.length === 0 ?<p>There is No Filter</p> : filterTable }
                            </section>
                            <footer className="modal-card-foot">
                                <button className="button" onClick={() => {dispatch(setModalsProps({...modalsProps,"modalRename" : false}));setRenameOutputModal(tempOutput)}} >Cancel</button>
                                <button className="button is-success" onClick={() => {
                                    if(renameOutputModal === tempOutput)
                                    {
                                        dispatch(setModalsProps({...modalsProps,"modalRename" : false}));
                                        setRenameOutputModal(tempOutput)          

                                    }   
                                    else if(renameOutputModal in OrigRenme[theCatalog] || renameOutputModal.includes(".\\/"))
                                        alert("You can't use this name")
                                    else
                                    {
                                        let count = Object.keys(getMulValues(tabMenuList,[theCatalog,"normal","tabs"],{})).filter(x => x === renameOutputModal || x.slice(0,x.indexOf("(")) === renameOutputModal).length
                                        if(count === 0 || renameOutputModal === tempOutput)
                                        {
                                            dispatch(setModalsProps({...modalsProps,"modalRename" : false}));
                                            dispatch(setRenameOutput(renameOutputModal));
                                            dispatch(setActionToDo("RenameOutput"))
                                        }
                                        else
                                        {
                                            dispatch(setModalsProps({...modalsProps,"modalRename" : false}));
                                            dispatch(setRenameOutput(renameOutputModal+"("+count+")"));
                                            dispatch(setActionToDo("RenameOutput")) 
                                        }
                                    }}}>Save changes</button>
                            </footer>
                        </div>
                </div>
            </>);
}

export default ModalRenameComp;