/* eslint-disable react-hooks/exhaustive-deps */

import React,{useEffect, useRef,useState} from 'react'
import { useCookies } from 'react-cookie';
import { useDispatch,useSelector} from "react-redux";

import { setOutputKind,setTheOutput,setActionToDo ,
setProgressBarValue,setCatalogDetails, setTabMenuList} from '../../../../redux/portal';

import { setOutputTitle } from '../../../../redux/customOutput';
import { setButtonOkLoad,setModalsProps } from '../../../../redux/modals';

import { mongoNodeApiCall } from '../functions';
import { ApiCall, compRegenMsg, compRegenMsgCurrency, getKeyFromValue, getValue, pythonUrl ,CurrencyDetails} from '../../../../Data/Variables';

const ModalGenerate = ({jwt,clientName,packs}) =>
{
    const { theCatalog ,catalogResFilt,theOutput,catalogDetails,tabMenuList} = useSelector((state) => state.portal);

    let output = theOutput.split("_");
    let outputName = output.slice(1).join('_')
    const { modalsProps,buttonOkLoad } = useSelector((state) => state.modals);
    const { propsOutput,outputTitle } = useSelector((state) => state.customOutput);
    const { withData,joinPayors,collection,period,concat,filterOption } = useSelector((state) => state.filter);
    const { email } = useSelector((state) => state.frame);
    const {OrigRenme,defualttaboutput,tabtofolderdraft} = useSelector((state) => state.globalVars);
    const [time,setTime] = useState(0);
   // const [outputAryClient,setOutputAryClient] = useState([]);
    const [, setCookies, ] = useCookies();
    const dispatch = useDispatch();
    const tick = useRef(); 
    useEffect(()=>
    {
        if (time === -1) 
        {
            dispatch(setProgressBarValue(100));
            clearInterval(tick.current);
            setTime(0);
        }
        else if(time > 0 && time !== 100)
            dispatch(setProgressBarValue(time));
    },[time])

    const generateCurrency = async (jwt) =>
    {           
        let exchange_rates = {}; 
        if(!modalsProps["modalFilter"])    
        {
            dispatch(setOutputTitle({...outputTitle,"currencies" :  propsOutput["currencies"],"currencyKey" : propsOutput["currencyKey"]}));
            if(modalsProps["modalGenerate"] !== "true")
                exchange_rates = modalsProps["modalGenerate"]
            else
            {
                mongoNodeApiCall("project", "update", {"projectString" : JSON.stringify({"currency" : propsOutput["currencies"][theCatalog], "_id": catalogDetails[theCatalog]["_id"]})});
                Object.keys(getValue(propsOutput["currencies"],theCatalog,{})).forEach((key) => 
                {
                    exchange_rates[key.toUpperCase()] = propsOutput["currencies"][theCatalog][key];
                });
            }
        }
        if(true)
        {
            function raiseError(err,alertMsg,dispatch,buttonOkLoad,setButtonOkLoad,modalsProps,setModalsProps,setTime)
            {
                console.log(err)
                alert(alertMsg);
                dispatch(setButtonOkLoad({...buttonOkLoad,"Generate" : false}));
                dispatch(setModalsProps({...modalsProps,"modalCustom":false,"modalGenerate" : "false"}));
                setTime(-1);
            }
            dispatch(setProgressBarValue(0));
            tick.current = setInterval(() => 
            {
                setTime((time) => time + 1);
            }, parseInt(catalogDetails[theCatalog]["StandardOutputTime"])*10);
            let colValues = Object.values(collection);

            let filter =  (((colValues.length > 0 && colValues[0].length > 0) || 

            (joinPayors.every((val) => val.length > 0)) || period !== "" || concat.length > 1 ||

            ((!catalogResFilt[theCatalog].every(element => {return  element !== undefined ? Object.keys(element).length === 0 || Object.values(element).length === 0: true}))))
            && modalsProps["modalFilter"] ? JSON.stringify(catalogResFilt[theCatalog]) : "") ;
            
            let tabName = "";
            let renamedFilter = {};
            if(filter === "") // no filter
                tabName = "Overview"
            else
            {
                if(filterOption)
                {
                    if(tabtofolderdraft[OrigRenme[theCatalog][outputName]].endsWith("GlobalDraft"))
                        tabName = OrigRenme[theCatalog][outputName];
                    else if(tabtofolderdraft[OrigRenme[theCatalog][outputName]].endsWith("Draft"))
                        tabName = "Global "+OrigRenme[theCatalog][outputName] // Draft to global 
                    else
                        tabName = "Global "+OrigRenme[theCatalog][outputName]+" Draft"// stan to global
                }
                else
                {
                    if(tabtofolderdraft[OrigRenme[theCatalog][outputName]].endsWith("GlobalDraft"))
                        tabName = OrigRenme[theCatalog][outputName].substring(7) // global to draft
                    else if(tabtofolderdraft[OrigRenme[theCatalog][outputName]].endsWith("Draft"))
                        tabName = OrigRenme[theCatalog][outputName]; 
                    else
                        tabName = OrigRenme[theCatalog][outputName]+" Draft" // stan to draft
                }
                tabName = getKeyFromValue(OrigRenme[theCatalog],tabName)
                renamedFilter = {[tabtofolderdraft[OrigRenme[theCatalog][tabName]]] : tabName}
            }
            let params = 
            {
                method: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : jwt},
                body: new URLSearchParams(
                {
                    "catalog_id" : catalogDetails[theCatalog]["_id"],
                    "returnValue": "true",
                    "filterOption" : filterOption,
                    "joinPayors" : JSON.stringify(joinPayors),
                    "collectionFilter"  : colValues.length > 0 && colValues[0].length > 0 ? JSON.stringify(collection) : "{}",
                    "period" : period,
                    "concat" : JSON.stringify(concat),
                    "renamedFilter" : JSON.stringify(renamedFilter),
                    "filterBy" : filter,
                    "withData" : withData ? "" : "not",
                    "userPath" : email,
                    "clientName" : clientName,
                    "catalogName" : theCatalog,
                    "currencyKey" : propsOutput["currencyKey"][theCatalog],
                    "outputAry" : JSON.stringify(packs),
                    "yearParam": "NormalData"
                })
            }
            let data = await ApiCall(pythonUrl+"/GenerateTabOutput",params,setCookies,(err) => 
            {
                raiseError(err,"Data Error. Please contact us at data@bitsonic.ai",dispatch,buttonOkLoad,setButtonOkLoad,modalsProps,setModalsProps,setTime)
            });
            data = await data.json();
            if(data["resMsg"] === "dfEmpty")
                raiseError("","The filter you are looking for is giving an empty result, Please contact us at data@bitsonic.ai",dispatch,buttonOkLoad,setButtonOkLoad,modalsProps,setModalsProps,setTime)
            else
            {
                if(data["StandardOutputTime"] === 0)
                    dispatch(setCatalogDetails({...catalogDetails,[theCatalog] : {...catalogDetails[theCatalog],"currency" : {...propsOutput["currencies"][theCatalog]}}}));
                else
                    dispatch(setCatalogDetails({...catalogDetails,[theCatalog] : {...catalogDetails[theCatalog] , "StandardOutputTime" : data["StandardOutputTime"],"currency" : {...propsOutput["currencies"][theCatalog]}}}));

                if(filter === "") // no filter
                {
                    dispatch(setTabMenuList({...tabMenuList,[theCatalog] : {...tabMenuList[theCatalog],[CurrencyDetails] : {...tabMenuList[theCatalog][CurrencyDetails],"details" : data.details}}}));
                    dispatch(setTheOutput(theCatalog+"_"+tabName));
                    dispatch(setOutputKind(getValue(defualttaboutput,OrigRenme[theCatalog][tabName],"")));
                    dispatch(setActionToDo("DeleteDrafts"));
                }
                else
                {
                    let preProps = {...tabMenuList[theCatalog][tabtofolderdraft[OrigRenme[theCatalog][outputName]]]};
                    preProps["tabs"] = data.tabs;
                    preProps["details"] = data.details;
                    console.log(preProps)
                    dispatch(setTabMenuList({...tabMenuList,[theCatalog] : {...tabMenuList[theCatalog],[tabtofolderdraft[OrigRenme[theCatalog][tabName]]] :  preProps}}));
                    dispatch(setOutputKind(getValue(defualttaboutput,OrigRenme[theCatalog][tabName],"")));
                    dispatch(setTheOutput(theCatalog+"_"+tabName));
                    dispatch(setActionToDo("AddOutputGet"));
                }

                dispatch(setModalsProps({...modalsProps,"modalCustom":false,"modalGenerate" : "false"}));
                setTime(-1);
                //clearFilters();
         
            }
        }

    }

    return (<>
    <div className={modalsProps["modalGenerate"] !== "false" ? "modal is-active" : "modal"} style={{"zIndex" : 55}}>

        <div className="modal-background"></div>
        <div className="modal-card" style={{"zIndex" : 55}}>
            <header className="modal-card-head has-text-centered" >
                <p className="modal-card-title">
                {modalsProps["modalCurrency"] ? compRegenMsgCurrency : compRegenMsg}
                </p>
            </header>
            <footer className="modal-card-foot buttons is-centered">
                <button className="button" onClick={()=>{dispatch(setModalsProps({...modalsProps,"modalGenerate" : "false"}))}}>No</button>
                <button className={buttonOkLoad["GetOutput"] ? "button is-success is-loading" : "button is-success"} onClick={() => {dispatch(setModalsProps({...modalsProps,"modalGenerate" : "false"}));generateCurrency(jwt)}}>Yes</button>
            </footer>
        </div>
    </div>
    </>);


}
export default ModalGenerate;