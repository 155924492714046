/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from 'react'
import { useCookies } from 'react-cookie';
import { useDispatch,useSelector} from "react-redux";

import { setGenerateTab } from '../../redux/makeTable';
import { setOgRename } from '../../redux/globalVars';
import { setButtonOkLoad,setModalsProps } from '../../redux/modals';
import {setTheOutput, setActionToDo, setOutputKind,
        setInProgressCata, setTabMenuList, setTempOutput} from '../../redux/portal'
import { AllSet, ApiCall, SORTED_DATA, getTabDetails, getUserPath, getValue, pythonUrl, repoTab } from '../../Data/Variables';


let firstTab = <></>

const RepoTab = ({catalogName,tabName}) =>
{
    const { theOutput, outputKind } = useSelector((state) => state.portal);
    const { generateTab } = useSelector((state) => state.makeTable);
    const dispatch = useDispatch();
    return (
        <>
            <li className={theOutput === (catalogName+"_"+tabName) || (theOutput === "" && tabName === outputKind) ? " is-active" : ""}>
                <a>
                    <span className='tag is-info is-light is-medium' onClick={(e) => 
                            { 
                                if(!generateTab)
                                {
                                    dispatch(setTheOutput(catalogName+"_"+tabName));
                                    dispatch(setActionToDo("GetRepo"))
                                }
                    }}><b>{tabName}</b></span>
                </a>
            </li>
        </>
    )
}

const MenuTab = (props) =>
{
    const { theOutput,tabMenuList,theCatalog,outputKind } = useSelector((state) => state.portal);
    const { generateTab } = useSelector((state) => state.makeTable);
    const { modalsProps } = useSelector((state) => state.modals);
    const dispatch = useDispatch();
    const {OrigRenme,tabtofolderdraft,defualttaboutput,tabtofolder} = useSelector((state) => state.globalVars);

    const [filter,setFilter] = useState(getTabDetails(tabMenuList,props.catalogName,props.tabName,"filter",tabtofolderdraft,OrigRenme[theCatalog]));
    const [concat,] = useState(getTabDetails(tabMenuList,props.catalogName,props.tabName,"concat",tabtofolderdraft,OrigRenme[theCatalog]));
    const [period,] = useState(getTabDetails(tabMenuList,props.catalogName,props.tabName,"period",tabtofolderdraft,OrigRenme[theCatalog]));
    const [joinPayors,] = useState(getTabDetails(tabMenuList,props.catalogName,props.tabName,"joinPayors",tabtofolderdraft,OrigRenme[theCatalog]));
    const [collection,] = useState(() =>
    {
        let x = getTabDetails(tabMenuList,props.catalogName,props.tabName,"collectionFilter",tabtofolderdraft,OrigRenme[theCatalog]);
        return  x === undefined ? [] : Object.values(x);
    });
    useEffect(() => {setFilter(getTabDetails(tabMenuList,props.catalogName,props.tabName,"filter",tabtofolderdraft,OrigRenme[theCatalog]))},[tabMenuList])
    let isFilter = (collection.length > 0 && collection[0].length > 0)||(joinPayors !== undefined && (joinPayors.every((val) => val.length > 0))) || (filter !== undefined  && filter.length > 0) || (concat !== undefined  && concat.length > 1) || (period !== undefined  && period !== "");
    return (<>
                <li className={theOutput === (props.catalogName+"_"+props.tabName) || (theOutput === "" && props.tabName === outputKind) ? " is-active" : ""}>
                    <a>
                        <span className={'is-medium ' + (isFilter  ? "tag is-warning is-light" : "")}>
                            {props.tabName !== "Overview" && !(props.tabName in AllSet) && (!(props.tabName in OrigRenme[theCatalog]) || (props.tabName in OrigRenme[theCatalog] && getValue(tabtofolderdraft,OrigRenme[theCatalog][props.tabName],"").includes("Draft")))
                            ? 
                                <button className={'button is-small mr-2 '+ ( isFilter  ? "is-warning is-light" : "")} onClick={() => 
                                {
                                    if(!generateTab) 
                                    {   
                                        dispatch(setTempOutput(props.tabName));
                                        dispatch(setModalsProps({...modalsProps,"modalRename" : true}));
                                    }
                                }} 
                                ><i className="fa-solid fa-file-pen"/></button>
                            : <></>}
                            <span className='mr-2' onClick={(e) => 
                            { 
                                if(!generateTab)
                                {
                                    dispatch(setGenerateTab(true));
                                    dispatch(setTheOutput(props.catalogName+"_"+props.tabName));
                                    dispatch(setOutputKind(getValue(defualttaboutput,OrigRenme[theCatalog][props.tabName],"")));
                                    dispatch(setActionToDo("GetOutput"));
                                }
                            }}>{props.tabName}</span>
                            {props.tabName !== "Overview" && !(props.tabName in AllSet) && (!(props.tabName in OrigRenme[theCatalog]) || getValue(tabtofolderdraft,OrigRenme[theCatalog][props.tabName], "").includes("Draft"))
                            ? 
                                <button className="delete is-small" onClick={() => 
                                {
                                        if(!generateTab)
                                        {
                                            dispatch(setTheOutput(props.catalogName+"_"+props.tabName));
                                            dispatch(setOutputKind(getValue(tabtofolder,OrigRenme[theCatalog][props.tabName],"")));
                                            dispatch(setActionToDo("DeleteOutput"));
                                        }}} 
                                    />
                            : <></>}
                        </span>
                    </a>
                </li> 
            </>);
}

const OutputMenu = (props) =>
{
    const [outputMenuList,SetOutputMenuList] = useState({});                          
    const { theOutput,inProgressCata,theCatalog,tabMenuList, tempOutput
            ,actionToDo,catalogDetails,renameOutput,outputKind } = useSelector((state) => state.portal);
    const { modalsProps,buttonOkLoad } = useSelector((state) => state.modals);
    const { email } = useSelector((state) => state.frame);
    const {OrigRenme,tabtofolderdraft,defualttaboutput,packstofolder,ordertabs,tabtofolder} = useSelector((state) => state.globalVars);

    const [numberTabs,SetNumberTabs] = useState(0);
    const dispatch = useDispatch();
    let output = theOutput.split("_");
    let outputName = output.slice(1).join('_')
    const [, setCookies, ] = useCookies();

    const deleteOutput = async () =>
    {
        dispatch(setGenerateTab(true));
        dispatch(setButtonOkLoad({...buttonOkLoad,"DeleteOutput" : true}));
        let params = 
        {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : props.jwt},
            body: new URLSearchParams(
            {
                "outputName" : outputName,
                "catalog_id" : catalogDetails[output[0]]["_id"],
                "userPath" : getUserPath("",email,props.clientName,OrigRenme,theCatalog,outputName,tabtofolderdraft),
                "clientName" : props.clientName,
                "catalogName" : theCatalog,
                "outputKind" : outputKind
            })
        }
        await ApiCall(pythonUrl+"/DeleteOutput",params,setCookies,(err) => 
        {
            console.log(err)
            alert("Data Error. Please contact us at data@bitsonic.ai");
            dispatch(setButtonOkLoad({...buttonOkLoad,"DeleteOutput" : false}));
        });
        dispatch(setButtonOkLoad({...buttonOkLoad,"DeleteOutput" : false}));
        dispatch(setGenerateTab(false));
    }

    useEffect(() => 
    {
        let tabMenuListAry = {}
        let index = 0;
        let myPacks = [...new Set(props.packs.map((val) => packstofolder[val]))];
        myPacks = [...myPacks,...myPacks.map(val => val+"_Draft"),...myPacks.map(val => val+"_GlobalDraft")];
        myPacks.push("overview");
        for(let cataName in tabMenuList) 
        {
            tabMenuListAry[cataName] = {};
            let ExistTabs = tabMenuList[cataName];
            let tabKeys = Object.keys(OrigRenme[cataName]).sort((a, b) => {return ( ordertabs.indexOf(a)-ordertabs.indexOf(b))});;
            for(let  i=0;i<tabKeys.length;i++)
            {
                if(cataName === "" || (tabtofolderdraft[OrigRenme[cataName][tabKeys[i]]] in ExistTabs &&  Object.keys(ExistTabs[tabtofolderdraft[OrigRenme[cataName][tabKeys[i]]]]["tabs"]).length > 0 && tabtofolderdraft[OrigRenme[cataName][tabKeys[i]]] !== "normal" && tabtofolderdraft[OrigRenme[cataName][tabKeys[i]]] !== "" && (myPacks.includes(tabtofolderdraft[OrigRenme[cataName][tabKeys[i]]]))))
                {
                    tabMenuListAry[cataName][tabKeys[i]] = ((<React.Fragment key={index}><MenuTab catalogName={cataName} tabName={tabKeys[i]}/></React.Fragment>));
                    index++;
                }
            }    
            if("normal" in tabMenuList[cataName])
            {
                let keys = Object.keys(getValue(tabMenuList[cataName]["normal"],"tabs",{}));
                for(let i=0;i<keys.length;i++)
                {
                    tabMenuListAry[cataName][keys[i]] = ((<React.Fragment key={index}><MenuTab catalogName={cataName} tabName={keys[i]}/></React.Fragment>));
                    index++;
                }
            }
        }
        SetNumberTabs(index);
        SetOutputMenuList(tabMenuListAry);
    },[]) 
    useEffect(() => 
    {
        if(actionToDo === "AddOutput" || actionToDo === "AddOutputGet")
        {
            if(actionToDo === "AddOutputGet")
                dispatch(setActionToDo("GetOutput"));
            else
                dispatch(setActionToDo(""));
            
            SetOutputMenuList({...outputMenuList,[theCatalog] : {...outputMenuList[theCatalog], [outputName] : <React.Fragment key={numberTabs}><MenuTab catalogName={theCatalog} tabName={outputName}/></React.Fragment>}});
            SetNumberTabs(numberTabs+1);
        }
        if(actionToDo === "DeleteOutput")
        {
            deleteOutput();
            let list = {...outputMenuList}
            delete list[theCatalog][outputName]
            SetOutputMenuList(list);

            if(!(outputName in OrigRenme[theCatalog]))
            {
                let preProps = {...tabMenuList[theCatalog]["normal"]["tabs"]};
                delete preProps[outputName];
                dispatch(setTabMenuList({...tabMenuList,[theCatalog] : {...tabMenuList[theCatalog],"normal" : {...tabMenuList[theCatalog]["normal"],"tabs" : preProps}}}));
            }
            else
            {
                let preProps = {...tabMenuList[theCatalog]};
                delete preProps["standard_Draft"];
                dispatch(setTabMenuList({...tabMenuList,[theCatalog] : preProps}));
            }
            firstTab = Object.keys(outputMenuList[theCatalog])[0];
            
            if(firstTab !== undefined)
            {
                dispatch(setTheOutput(theCatalog+"_Overview"));
                dispatch(setOutputKind("Catalogue Details"));
                dispatch(setActionToDo("GetOutput"));    
            }
            else
                dispatch(setActionToDo("ClearTable"));
        }
        if(actionToDo === "DeleteDrafts")
        {
            dispatch(setActionToDo(""));    
            let list = {...outputMenuList}
            Object.keys(list[theCatalog]).forEach((key) =>
            {
                if((key in OrigRenme[theCatalog] && tabtofolderdraft[OrigRenme[theCatalog][key]].includes("Draft")) || (!(key in OrigRenme[theCatalog])))
                    delete list[theCatalog][key];
            })
            SetOutputMenuList(list);

            let preProps = {...tabMenuList[theCatalog]};
            delete preProps["standard_Draft"];
            delete preProps["normal"];
            dispatch(setTabMenuList({...tabMenuList,[theCatalog] : preProps}));
            dispatch(setActionToDo("GetOutput"));    
        }
        if(actionToDo === "SetFirstTab")
        {
            dispatch(setInProgressCata(false));
            firstTab = Object.keys(outputMenuList[theCatalog])[0];  
            if(firstTab !== undefined)
            {
                dispatch(setTheOutput(theCatalog+"_"+firstTab));
                dispatch(setOutputKind(getValue(defualttaboutput, OrigRenme[theCatalog][firstTab],"")));
                dispatch(setActionToDo("GetOutput"));
            }
            else
                dispatch(setActionToDo("ClearTable"));
        }
        if(actionToDo === "RenameOutput")
        {
            const RenameOutputFunc = async (output,renameOutput,catalogDetails,email,theCatalog,dispatch,outputMenuList,setTabMenuList,tabMenuList,numberTabs,SetNumberTabs,outputname) =>
            {
                dispatch(setActionToDo(""));
                let params = 
                {
                    method: 'POST',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : props.jwt},
                    body: new URLSearchParams(
                    {
                        "catalog_id" : catalogDetails[output[0]]["_id"],
                        "outputOld" : tempOutput,
                        "outputNew" : renameOutput,
                        "userPath" : getUserPath("",email,props.clientName,OrigRenme,theCatalog,tempOutput,tabtofolderdraft),
                        "clientName" : props.clientName,
                        "catalogName" : theCatalog
                    })
                }
                await ApiCall(pythonUrl+"/RenameOutput",params,setCookies,(err) => 
                {
                    console.log(err);
                    alert("Data Error. Please contact us at data@bitsonic.ai");
                });
                console.log("onetime")
                let preProps = {...tabMenuList[theCatalog]["normal"]["tabs"]};

                preProps[renameOutput] = JSON.parse(JSON.stringify(preProps[tempOutput]));
                if(tempOutput !== renameOutput)
                    delete preProps[tempOutput];
                dispatch(setTabMenuList({...tabMenuList,[theCatalog] : {...tabMenuList[theCatalog],"normal" : {...tabMenuList[theCatalog]["normal"],"tabs" : preProps}}}));
                
                let ary = {...outputMenuList[theCatalog]};
                delete ary[tempOutput];
                ary[renameOutput] = <React.Fragment key={numberTabs}><MenuTab catalogName={theCatalog} tabName={renameOutput}/></React.Fragment>

                dispatch(setTheOutput(output[0]+"_"+renameOutput));                    
                dispatch(setOutputKind(getValue(defualttaboutput,OrigRenme[theCatalog][props.tabName],"")));
                dispatch(setActionToDo("GetOutput"));
                
                SetOutputMenuList({...outputMenuList,[theCatalog] : ary});
                SetNumberTabs(numberTabs+1);
    
            }
                console.log(tempOutput , OrigRenme[theCatalog])
                if(!(tempOutput in OrigRenme[theCatalog]))
                    RenameOutputFunc(output,renameOutput,catalogDetails,email,theCatalog,dispatch,outputMenuList,setTabMenuList,tabMenuList,numberTabs,SetNumberTabs);
                else
            {
                const RenameTabFunc = async (output,tempOutput,renameOutput,catalogDetails,email,theCatalog,dispatch,outputMenuList,numberTabs,SetNumberTabs,OrigRenme,setOgRename,specialDictTab,specialDictPath,jwt) =>
                {
                    let params = 
                    {
                        method: 'POST',
                        headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : jwt},
                        body: new URLSearchParams(
                        {
                            "catalog_id" : catalogDetails[output[0]]["_id"],
                            "outputOld" : specialDictTab[OrigRenme[theCatalog][tempOutput]],
                            "outputNew" : renameOutput,
                            "pathKind" : specialDictPath[OrigRenme[theCatalog][tempOutput]],
                            "userPath" : getUserPath("",email,props.clientName,OrigRenme,theCatalog,tempOutput,tabtofolderdraft),
                        })
                    }
                    await ApiCall(pythonUrl+"/RenameSpecial",params,setCookies,(err) => 
                    {
                        console.log(err)
                        alert("Data Error. Please contact us at data@bitsonic.ai");
                    });
                    let OgRenameAry = {...OrigRenme[theCatalog]};
                    OgRenameAry[renameOutput] = OgRenameAry[tempOutput];
                    delete OgRenameAry[tempOutput];

                    dispatch(setOgRename({...OrigRenme,[theCatalog] : OgRenameAry}));               
                    let ary = {...outputMenuList[theCatalog]};
                    delete ary[tempOutput];
                    
                    ary[renameOutput] = <React.Fragment key={numberTabs}><MenuTab catalogName={theCatalog} tabName={renameOutput}/></React.Fragment>
                    dispatch(setTheOutput(output[0]+"_"+renameOutput));               
                    dispatch(setOutputKind(getValue(defualttaboutput,OrigRenme[theCatalog][tempOutput],"")));
                    dispatch(setActionToDo("GetOutput"));
                    SetOutputMenuList({...outputMenuList,[theCatalog] : ary});
                    SetNumberTabs(numberTabs+1);
                }
                RenameTabFunc(output,tempOutput,renameOutput,catalogDetails,email,theCatalog,dispatch,outputMenuList,numberTabs,SetNumberTabs,OrigRenme,setOgRename,tabtofolderdraft,tabtofolder,props.jwt);
                
            }
        }
    },[actionToDo]);
    const IsRepo =  theCatalog !== undefined && theCatalog !== "" && SORTED_DATA in tabMenuList[theCatalog];
    return (
        <>
            <div className='tabs is-boxed mt-0 mb-2' style={{"overflowX" : "hidden","width" : "100%"}}>
                <div className='columns' style={{"width" : "100%"}}>
                    <div className={IsRepo ? 'column pb-0 pr-5 is-10' : 'column pb-0 pr-5 is-11'}>
                        <ul className='scrollTabs'>
                            <div className={inProgressCata ? "field has-addons disabledButtons" : "field has-addons"}>
                                {theCatalog !== undefined ?  
                                    <>
                                        {Object.values(outputMenuList[theCatalog])}
                                    </>
                                : null}
                                
                                {theCatalog !== undefined ?  
                                    <li className={theOutput === props.catalogName+"_"+props.tabName ? " is-active" : ""} onClick={() => {dispatch(setModalsProps({...modalsProps, "modalCustom" : true}));}}><a><span><b>+</b></span></a></li>
                                :<></>}
                            </div>
                        </ul>   
                    </div>
                    {
                        IsRepo?
                        <div className='column is-2'>
                            <ul className='scrollTabs'>
                                <RepoTab catalogName={theCatalog} tabName={repoTab} />
                            </ul>
                        </div> 
                    : null
                    }
                </div>
                
    
                
                
            </div>

        </>);
}
  
export default OutputMenu;