/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect, useState} from 'react'
import { useDispatch,useSelector} from "react-redux";
import { useCookies } from 'react-cookie';

import { setGenerateTab } from '../../redux/makeTable';
import {setTheCatalog,setActionToDo} from '../../redux/portal'
import { setConcat } from '../../redux/filter';

import { setModalsProps } from '../../redux/modals';
import { ApiCall, pythonUrl } from '../../Data/Variables';

const ShowValuesByKeyWord = (dict,keyword) =>
{
    let ary = [];
    let keys = Object.keys(dict);
    for (let i = 0;i< keys.length;i++)
    {
        if(keys[i].toLowerCase().includes(keyword.toLowerCase()))
            ary.push(dict[keys[i]]);
    }   
    let len = ary.length;
    if(ary.length === 0)
        ary.push(<React.Fragment key={0}><tr><td className='has-text-centered'>None</td></tr></React.Fragment>)
    
    return [ary,len];
}
    const CatalogClicked = async (e,cataID,dispatch,inProgress,catalogName,updateSeen,email,jwt,setCookies) =>
    {
        dispatch(setTheCatalog(catalogName));
        dispatch(setConcat([{"value" : cataID,"label" : catalogName}]));
        dispatch(setGenerateTab(true));
        if(!inProgress)
            dispatch(setActionToDo("SetFirstTab")) 
        else 
            dispatch(setActionToDo("In-ProgressCatalog"))

        if(!updateSeen)
        {
            let params = 
            {
                method: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : jwt},
                body: new URLSearchParams(
                {
                    "email" : email,
                    "varToUpdate" : "UpdateSeen",
                    "valueToUpdate" : "true",
                    "projectId" : cataID
                })
            }
            await ApiCall(pythonUrl+"/UpdateMongo",params,setCookies,(err) => 
            {
                console.log(err)
                alert("Data Error. Please contact us at data@bitsonic.ai");
            });
        }        
    }
const CatalogComp = (props) =>
{
    
    const dispatch = useDispatch();
    const { theCatalog } = useSelector((state) => state.portal);
    const [updateSeen,setUpdateSeen] = useState(props.updateSeen)
    const { email } = useSelector((state) => state.frame);
    const [, setCookies, ] = useCookies();


    return (<tr><td ><li onClick={(e) => {setUpdateSeen(true);CatalogClicked(e,props.cataId,dispatch,props.inProgress,props.catalogName,updateSeen,email,props.jwt,setCookies)}} >
                <a className={(props.catalogName === theCatalog) ? "is-active" : ""}>

                        <div className='columns is-vcentered'>
                            <div className='column is-2'>
                                {!updateSeen ? 
                                        <span className="dot circleUpdate is-pulled-left " />
                                : null}
                            </div>
                            <div className='column'>
                                <span className='is-gapless is-marginless  ' style={{"wordWrap": "break-word","whiteSpace": "pre-wrap"}}>{props.catalogName}</span>
                            </div>
                            <div className='column is-narrow '>
                                <span className={props.inProgress && props.hasFrame ? "tag icon is-small ml-1 is-warning is-pulled-right  " : !props.hasFrame ? "tag icon is-small ml-1 has-background-grey-light is-pulled-right " : "tag icon is-small ml-1 is-success is-pulled-right "} >
                                        {props.inProgress && props.hasFrame ? <i className="fa-solid fa-gears "/> : !props.hasFrame ? <></>  : <i className="fa-solid fa-check"/>}
                                </span>
                            </div>
                        </div>
                </a>
            </li>
            </td></tr>);
}
const CatalogMenu = (props) =>
{
    const [catalogMenuList,SetCatalogMenuList] = useState({"owned" : [],"" : []});       
    const [catalogInSearch,setCatalogInSearch] = useState("");                          
    const dispatch = useDispatch();
    const { theCatalog } = useSelector((state) => state.portal);
    const { alloutput_uptodate } = useSelector((state) => state.frame);
    const { modalsProps } = useSelector((state) => state.modals);

    useEffect(() => 
    {
        let catalogMenuAry = {"owned" : [],"" : []}
        let index = 0;
        let Catakeys = Object.keys(props.catalogMenuList).sort();
        for(let i=0;i<Catakeys.length;i++) 
        {
           catalogMenuAry[props.isAdmin ? "owned" : props.catalogMenuList[Catakeys[i]]["catalogStatus"]][Catakeys[i]] = (<React.Fragment key={index}><CatalogComp catalogName={Catakeys[i]} cataId={props.catalogMenuList[Catakeys[i]]["id"]} inProgress={props.catalogMenuList[Catakeys[i]]["inProgress"]} updateSeen={props.catalogMenuList[Catakeys[i]]["UpdateSeen"]} hasFrame={props.catalogMenuList[Catakeys[i]]["hasFrame"]} jwt={props.jwt} /></React.Fragment>);
           index++;
        }
        SetCatalogMenuList(catalogMenuAry);
    },[]) 
    let [owned,ownlen] = ShowValuesByKeyWord(catalogMenuList["owned"],catalogInSearch);
    let [ddcatalogs,ddlen] = ShowValuesByKeyWord(catalogMenuList[""],catalogInSearch);
    return (<>
           <aside className="menu">
                <center>
                    <div className="control"><input className="input has-text-centered" type="text" placeholder="Search Catalogue" onChange={(e) => setCatalogInSearch(e.currentTarget.value)}/></div>
                </center>
                <br/>
                <ul className="menu-list ">
                    {props.hasAllOutput ?
                    <>
                        <center><p className="menu-label mb-2 has-text-weight-bold	">ALL Owned</p></center>
                        <li className={'grayBackcolor '} onClick={() => 
                        {
                            if(!alloutput_uptodate)  
                                dispatch(setModalsProps({...modalsProps,"modalAllOutput" : true})); 
                            else
                            {
                                dispatch(setGenerateTab(true));
                                dispatch(setTheCatalog(""));
                                dispatch(setActionToDo("SetFirstTab"));
                            }
                        }}>
                            <a className={(theCatalog === "" ? "is-active" : "")} >
                                <div className='columns is-vcentered'>
                                    <div className='column is-narrow' />
                                    <div className='column'><center><span>All Output</span></center></div>
                                    <div className='column is-narrow'>
                                    {!alloutput_uptodate  
                                        ? 
                                            <span className={"icon ml-1 mt-1 has-text-danger"} >
                                                <i className="fa-solid fa-flag" />
                                            </span>  
                                        : <></>}
                                    </div>
                                </div>
                            </a>
                            
                        </li>
                        <br/>
                    </>: null}
                    
                    <center><p className="menu-label mb-2 has-text-weight-bold	">Owned ({ownlen})</p></center>
                    <table className='table is-striped is-narrow is-fullwidth' style={{"tableLayout": "fixed"}}>
                        <tbody>
                            {owned}
                        </tbody>
                    </table>

                    <center><p className="menu-label mb-2 has-text-weight-bold	">Due Diligence ({ddlen})</p></center>
                    <table className='table is-striped is-narrow is-fullwidth' style={{"tableLayout": "fixed"}}>
                        <tbody>
                               {ddcatalogs}
                        </tbody>
                    </table>
                </ul>
            </aside>

            </>);
}
export default CatalogMenu;