/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import {useDispatch,useSelector} from "react-redux";

import {setActionToDo,setOutputKind} from '../../../redux/portal';
import { setGenerateTab } from '../../../redux/makeTable';
import { getMulValues } from '../../../Data/Variables';


const TabComp = (props) =>
{
    const { outputKind } = useSelector((state) => state.portal);
    const { generateTab } = useSelector((state) => state.makeTable);
    
    const dispatch = useDispatch();
    return <li className={outputKind === props.tabName ? 'is-active' : ''} onClick={() => 
    {
        if(!generateTab){
            dispatch(setGenerateTab(true));
            dispatch(setOutputKind(props.tabName));
            dispatch(setActionToDo("GetOutput"));}
    }}><a><span>{props.tabName}</span></a></li>
}
const PageSelect = ({index,pageing,setPageing}) =>
{
    return <li><a className={"pagination-link" + (pageing === index ? " is-current" : "")} onClick={(e) => setPageing(index)} >{index+1}</a></li>
}

const pagingConst = 100;
const TableContant = (props) =>
{
    const { currTableIsFullH,inProgressCata,theOutput ,tabMenuList,theCatalog} = useSelector((state) => state.portal);
    const {OrigRenme,tabtofolderdraft} = useSelector((state) => state.globalVars);
    const [aryTabs,setAryTabs] = useState(<></>);
    const [pageing,setPageing] = useState(0);
    useEffect(() =>
    {
        let output = theOutput.split("_");
        let outputName = output.slice(1).join('_')
        let tabsTemp = theCatalog !== undefined ? Object.keys(getMulValues(tabMenuList,[theCatalog,tabtofolderdraft[OrigRenme[theCatalog][outputName]],"tabs"],{})) : [];
        if(theCatalog === "")
            tabsTemp = Object.keys(getMulValues(tabMenuList,[theCatalog,"ALL","tabs"],{}))
        
        console.log(getMulValues(tabMenuList,[theCatalog],{}));
        let tabs = [];
        for(let i=0;i<tabsTemp.length;i++)
        {
            tabs.push(tabsTemp[i]);
        }
        let ary = [];
        for(let i = 0;i<tabs.length;i++)
        {
            ary.push(<React.Fragment key={i}><TabComp tabName={tabs[i]} /></React.Fragment>);
        }
        setAryTabs(ary);
    },[theOutput])
    const createPaging = () =>
    {
        let ary = [];
        for(let i=0;i<=Math.floor(props.tables.length/pagingConst);i++)
        {
            ary.push(<React.Fragment key={i}><PageSelect index={i} pageing={pageing} setPageing={setPageing}/></React.Fragment>)
        }
        return (Array.isArray(props.tables) && props.tables.length > pagingConst ? 
            <nav className="pagination has-background-white p-2" role="navigation" aria-label="pagination">
                <ul className="pagination-list">
                    {ary}
                </ul>
            </nav> : <></>)
    }
    return (
        <>
        <div className={'box mb-0'} style={{"height" : "calc("+props.precent+(Array.isArray(props.tables) && props.tables.length > pagingConst ? " - 2.5em" : "")+")"
                ,"padding": "0px","overflow" :"hidden","zIndex": 10,"position" : "relative"}}>
            {props.delButton}
            {theCatalog !== undefined && (props.output in OrigRenme[theCatalog] || props.output === "All Output") && props.output !== "Overview" && !inProgressCata
                    ?
                    <div className='tabs is-centered is-boxed is-fullwidth mb-0'>
                        <ul>
                            {aryTabs}
                        </ul>
                    </div>
                    :<></>}
            {currTableIsFullH ?
                <div className='columns is-vcentered is-flex-direction-column is-justify-content-center fullHeight scrollCont' >
                    
                    {props.loaderGap  ? <div className='loaderGap'><center><div className='loaderCss'></div></center></div> : <></>}
                    {Array.isArray(props.tables) ? 
                        <div className='scrollCont'>
                            {props.tables.slice(pageing*pagingConst,pageing*pagingConst+pagingConst)}
                        </div>
                    : props.tables}
                </div>
            :
                <div style={{"height" : "100%"}}>
                    {props.loaderGap ? <div className='loaderGap'><center><div className='loaderCss'></div></center></div> : <></>}
                    {Array.isArray(props.tables) ? 
                        <div className='scrollCont'>
                            {props.tables.slice(pageing*pagingConst,pageing*pagingConst+pagingConst)}
                        </div>
                    : props.tables}
                </div>}
        </div>
        <div>
            {createPaging()}
        </div>
        </>)
}
export default TableContant;