/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState,useRef} from 'react';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from "react-redux";

import { setActionToDo } from '../../redux/portal';
import { setGenerateTab } from '../../redux/makeTable';

import '../../table.css'
import { ApiCall, CurrencyDetails, OVERVIEW_TITLE, getMulValues, getTabDetails, getValue, pythonUrl, titleColumns } from '../../Data/Variables';

/*
const optimizeWith = (text,number) =>
{
    if(text.includes("\n"))
        return text;

    for(let i = 1;i<(text.length/(25*number));i++)
    {
        text = text.substring(0,
            
            i*25*number)+"\n"+text.substring(i*25*number);
    }
    return text;
}
*/
const TdSpotifySong = (props) =>
{
    return <td style={{"background" : (props.y%2 === 0 ? `rgb(250, 250, 250)` : `white`),"position" : "sticky","left" : 0}}>
                <span className={props.tagClass}>{props.contant}{props.SpotyURL != null && props.SpotyURL.trim() !== "" &&  props.SpotyURL != null ? <span className="icon is-pulled-right" onClick={() => window.open(props.SpotyURL, "_blank")} style={{"cursor" : "pointer"}}><i className="fa-brands fa-spotify"/></span> : null}</span>
           </td>;

}
const TdPivot = (props) =>
{
    const { theOutput,theCatalog,outputKind } = useSelector((state) => state.portal);
    const { outputTitle } = useSelector((state) => state.customOutput);
    const { email } = useSelector((state) => state.frame);

    let output = theOutput.split("_");
    let outputName = output.slice(1).join('_')

    const dispatch = useDispatch();
    const [isPivot,setIsPivot] = useState(outputName === "Overview" || props.IsThird ? false : props.isPivot);
    const { actionToDo,tabMenuList } = useSelector((state) => state.portal);
    const { OrigRenme,tabtofolderdraft} = useSelector((state) => state.globalVars);
    const [, setCookies, ] = useCookies();

    
    const pivotTable = async (jwt) =>
    {
        props.setTable(<center><div className='loaderCss' ></div></center>);

        let params = 
        {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : jwt},
            body: new URLSearchParams(
            {
                "catalog_id" : props.dataDir,
                "userPath" : email,
                "catalogName" : theCatalog,
                "clientName" : props.clientName,
                "Titles" : JSON.stringify(props.titles),
                "Values" : JSON.stringify(props.values),
                "currencyMode": getMulValues(tabMenuList[theCatalog],[CurrencyDetails,"details","currencyMode"],"Fixed"),
                "currency" : 1/outputTitle["currencies"][theCatalog][outputTitle["currencyKey"][theCatalog].toLowerCase()],
                "yearState" : props.yearState,
                "outputName" : outputName,
                "tabName" : outputKind,
                "details" : JSON.stringify(getTabDetails(tabMenuList,theCatalog,outputName,"",tabtofolderdraft,OrigRenme[theCatalog]))
            })
        }
        let data = await ApiCall(pythonUrl+"/GetPivot",params,setCookies,(err) => 
        {
            console.log(err)
            alert("Data Error. Please contact us at data@bitsonic.ai");
        });
        data = await data.json();
        props.setPivotTable(data);
        dispatch(setActionToDo("pivotActive_"));
    }
    useEffect(() =>
    {
        if(actionToDo.startsWith("pivot_"))
        {
            let ary = actionToDo.split("_");
            setIsPivot(props.IsThird ? false :JSON.parse(ary[1]));
        }        
    }, [actionToDo])
    return  <td onClick={() => {return (isPivot ? pivotTable(props.jwt) : null)}} 
                className={(props.match ? "has-text-right" : "has-text-left") + (isPivot ? " cellPivot" : "")}
                style={{"background" : (props.y%2 === 0 ? `rgb(250, 250, 250)` : `white`),"cursor" : (isPivot ? "pointer" :""),...props.isTotal}}
            >
                    <span className={props.tagClass}>{props.tdVal}</span>
            </td>
}

const MakeTable = (props) =>
{

    const { theOutput,actionToDo,theCatalog } = useSelector((state) => state.portal);
    const {OrigRenme} = useSelector((state) => state.globalVars);

    const [renderTime,SetRenderTime] = useState(0);
    const [isPivot,setIsPivot] = useState(props.isPivot);

    const [thirdWidth,setThirdWidth] = useState(0);
    const [stickyAry,setStickyAry] = useState([]);
    const [table,setTable] = useState(null);
    const [createTableVar,setCreateTable] = useState(false);
    const [pageNav,setPageNav] = useState(0);
    const [refsWidth,setRefsWidth] = useState([]);
    const [pageList,setPaginglist] = useState([]);
    const [trAryReal,setTrAryReal] = useState([]);
    const [thAryReal,setThAryReal] = useState([]);
    const [lastRow,setlastRow] = useState([]);

    const [pivotTable,setPivotTable] = useState({});
    const [sortBy,setSortBy] = useState({});

    const [stash,setStash] = useState(<></>);
    const prevNav = useRef([]);
    const dispatch = useDispatch();
    const clickPage = (num) =>
    {
        prevNav.current = prevNav.current.filter(element => {return element !== null;});
        console.log(prevNav)
        setTable(<center><div className='loaderCss' ></div></center>);
        prevNav.current[pageNav].className = "pagination-link";
        let number = parseInt(num)-1
        setPageNav(number);
        prevNav.current[number].className = "pagination-link is-current";
        setCreateTable(true);
    }
    const createTable = () =>
    {
        setTable(
            <>
                <div className='table-container box' style={{"overflowY" : "scroll","overflowX" :"none","padding": "0","height" : trAryReal[pageNav].length < 13 ? "" : (theOutput.split("_").slice(1).join('_') in OrigRenme[theCatalog] || theOutput === "_All Output") ? (trAryReal.length > 1 ? "75%" : "85%") : (trAryReal.length > 1 ? "90%" : "100%")}} >
                    {stash}
                    <table className="table is-hoverable is-scrollable is-gapless is-striped is-narrow tableSticky has-sticky-header has-sticky-footer">
                        <thead>
                            {thAryReal}
                        </thead>
                        <tbody >
                            {trAryReal[pageNav]}
                        </tbody>
                        <tfoot>
                            <tr>
                                {lastRow}
                            </tr>
                        </tfoot>
                    </table>
                    <br/>
                </div>
            </>
            );
    }
    const refs = useRef([]);
    const refThird = useRef();
    const getPosLeft = (ary,x,stickyAry) =>
    {
        let sum = 0;
        for(let i=x;i>=0;i--)
        {
            if(stickyAry[i])
                sum += ary[i];
        }
        return isNaN(sum) ? 0 : sum;
    }
    const getPosRight = (ary,x,stickyAry) =>
    {
        let sum = 0;
        for(let i=x;i<ary.length;i++)
        {
            if(stickyAry[i])
                sum += ary[i];
        }
        return isNaN(sum) ? 0 : sum;
    }
    const createTablePivot  = (sortByParam,sortBy,first) =>
    {

        let maxLines = 52500;
        let pageSplit = 2500;
        let thAry = [];
        let trAry = [];
        let myTable = pivotTable;
        let th = Object.keys(myTable);
        let tr = Object.values(myTable);
        if(th.length > 0)
        {
            /////trrrrrrrrr
            let royaltyState = "";
            for(let y=0;y<tr[0].length && y < maxLines;y++)
            {
                let tdAry =[];
                
                for(let x=0;x<tr.length;x++)
                {
                    let classname = "";
                    if(th[x].includes("Royalty"))
                    {
                        classname ="has-text-right"
                        royaltyState = th[x];
                    }
                    tdAry.push(<React.Fragment key={y*tr[0].length+x}><td className={classname}><span>{tr[x][y]}</span></td></React.Fragment>);
                }
                trAry.push(<React.Fragment key={y}><tr>{tdAry}</tr></React.Fragment>);
            }
            /////trrrrrrrrr
            
            // sortttt
            let sortBydict = {...sortBy};
            if(first)
                sortBydict[royaltyState] = true;
            if(sortByParam !== "")
            {
                if (!(sortByParam in sortBydict))
                    sortBydict[sortByParam] = false;
                    
                sortBydict[sortByParam] = !sortBydict[sortByParam];     
                
                sortBydict = {[sortByParam] : sortBydict[sortByParam]}
                setSortBy(sortBydict); 
                console.log(myTable[sortByParam]);
                if(sortByParam.includes("Royalty"))
                    trAry.sort((a, b) => {return (sortBydict[sortByParam] ? parseFloat(myTable[sortByParam][trAry.indexOf(b)].replace(",",""))-parseFloat(myTable[sortByParam][trAry.indexOf(a)].replace(",","")) : parseFloat(myTable[sortByParam][trAry.indexOf(a)].replace(",",""))-parseFloat(myTable[sortByParam][trAry.indexOf(b)].replace(",","")))});
                else
                    trAry.sort((a, b) => {return (sortBydict[sortByParam] ? myTable[sortByParam][trAry.indexOf(a)].localeCompare(myTable[sortByParam][trAry.indexOf(b)]) : -1*myTable[sortByParam][trAry.indexOf(a)].localeCompare(myTable[sortByParam][trAry.indexOf(b)]))});
            }
            // sortttt
            /////////////////thhhhhhh
            for(let i=0;i<th.length;i++)
            {
                thAry.push(<React.Fragment key={i}>
                <th onClick={(e) => {setTable(<center><div className='loaderCss' ></div></center>);dispatch(setActionToDo("pivotActive_"+e.currentTarget.id))}} id={th[i]} className='title is-6' style={{"cursor" : "pointer"}} >{th[i]}
                    <span className="icon">{(th[i] in sortBydict && sortBydict[th[i]]) || (th[i].includes("Royalty") && sortByParam === "") ? <i className="fa-solid fa-sort-up"></i> : <i className="fa-solid fa-sort-down"></i> }</span>
                </th></React.Fragment>)
            }
            /////////////////thhhhhhh

            //////paging
            let paginglist = [];
            
            if(trAry.length > pageSplit)
            {
                let newAry = []
                for(let i=pageSplit;i<=trAry.length;i+= pageSplit)
                {
                    if(i+pageSplit <= trAry.length)
                    {
                        paginglist.push(<React.Fragment key={paginglist.length}><li><a className={i===pageSplit ? "pagination-link is-current" : "pagination-link"} ref={el => (prevNav.current = [...prevNav.current, el])} onClick={(e) => dispatch(setActionToDo("clickPage_"+e.currentTarget.innerText))} aria-label={"Goto page "+(paginglist.length+1)}>{paginglist.length+1}</a></li></React.Fragment>);   
                        newAry.push(trAry.slice(i-pageSplit,i))
                    }
                    else
                        newAry.push(trAry.slice(i-pageSplit,trAry.length))

                }
                paginglist.push(<React.Fragment key={paginglist.length}><li><a className={"pagination-link"} ref={el => (prevNav.current = [...prevNav.current, el])} onClick={(e) => dispatch(setActionToDo("clickPage_"+e.currentTarget.innerText))} aria-label={"Goto page "+(paginglist.length+1)}>{paginglist.length+1}</a></li></React.Fragment>);   
                trAry = newAry;
            }
            else
                trAry = [trAry];

            //////paging
            setPaginglist(paginglist);
            setTrAryReal(trAry);
            setThAryReal(thAry);
            setlastRow(null);
            if(first)
                dispatch(setActionToDo("pivotPaging_"+props.index));
            else
                dispatch(setActionToDo(""));

            setCreateTable(true);
        }
    }
    const makeTable = async ( isref,stickyAry,jwt) =>
    {
        let outputName = theOutput.split("_").slice(1).join('_')
        let tabName = props.tableName;
        let table = props.tableData;
        let lastRow = [];
        let th = Object.keys(table);
        let stash = <></>;
        let tr = Object.values(table);
        if(isref)
            setStickyAry(Array(th.length).fill(true));
        

        let thAry = [];
        let titleCount = 0;
        const stickOn = (e) =>
        {
            let ary = [...stickyAry];
            ary[e.currentTarget.id] = !ary[e.currentTarget.id];
            setStickyAry(ary);
            SetRenderTime(1);
        }
        let refToUse = refsWidth;
        if(refsWidth.length === 0)
        {
            setRefsWidth(refs.current.map(x => x != null ? x.offsetWidth : null));
            refToUse = refs.current.map(x => x != null ? x.offsetWidth : null);
        }
        for(let j=0;j<th.length;j++)
        {
            //if(th[j] == "Total Revenue($)")
            //    th[j] = "Total Revenue"
            //if(tabName === "Catalogue Details" && (j === th.length-2)) // Adjusted
            //    thAry.push(<React.Fragment key={j}><th className='title is-6' ref={isref ? el => (refs.current = [...refs.current, el]) : null} >{th[j]+"("+outputTitle["currencyKey"][theCatalog]+")"}</th></React.Fragment>);
            if(isNaN(th[j].split(" ")[0]) && j< th.length-3 && stickyAry[j] && outputName !== "Overview")
            {
                thAry.push(<React.Fragment key={j}><th className='title is-6'  ref={isref ? el => (refs.current = [...refs.current, el]) : null}   style={{"background" : "white","position" : "sticky","zIndex" : 15,"left" : getPosLeft(refToUse,j-1,stickyAry) }}>{th[j]} <button className={'button is-success is-small tag is-rounded'} id={j} onClick={(e)=> {stickOn(e)}}><i className="fa-solid fa-thumbtack"></i></button></th></React.Fragment>);
                titleCount++;    
            }
            else if(isNaN(th[j].split(" ")[0]) && stickyAry[j] && outputName !== "Overview")
                thAry.push(<React.Fragment key={j}><th className='title is-6'  ref={isref ? el => (refs.current = [...refs.current, el]) : null}  style={{"background" : "white","position" : "sticky","zIndex" : 15,"right" :  getPosRight(refToUse,j+1,stickyAry) }}>{th[j]} <button className={'button is-success is-small tag is-rounded'} id={j} onClick={(e)=> {stickOn(e)}}><i className="fa-solid fa-thumbtack"></i></button></th></React.Fragment>);
            else
            {
                let addition = <></>
                if(isNaN(th[j].split(" ")[0]) && outputName !== "Overview")
                {
                    
                    if(j < th.length-3)
                        titleCount++;
                    addition = <button className={'button is-small tag is-rounded'} id={j} onClick={(e)=> {stickOn(e)}}><i className="fa-solid fa-thumbtack"></i></button>;
                }
                thAry.push(<React.Fragment key={j}><th className={'title is-6 ' + (j !== 0 ? 'has-text-centered' : '')} ref={isref ? el => (refs.current = [...refs.current, el]) : null} >{th[j]}{addition}</th></React.Fragment>);
            }
        }
        
        let trAry = [];
        let IsThird = (tr[0][0]+"").startsWith("(") && tr[0][0].endsWith(")")
        let isOverview = tabName === "Catalogue Details" || outputName === "Overview";
        let maxLines = 52500;

        let isAccountTitle = tr[0][0] === OVERVIEW_TITLE[OVERVIEW_TITLE.length-1]

        for(let y=isOverview ? 1 : 0;y<tr[0].length && y < maxLines;y++)
        {
            let tdAry = [];
            for(let x=0;x<tr.length;x++)
            {
                let tagClass = "";//"tag is-rounded";
                if(tabName === "Catalogue Details")
                {
                    if(th[x] === titleColumns[6])
                        tagClass = "tag is-primary is-light";
                    if(th[x] === titleColumns[3])
                        tagClass = "tag is-info is-light"; 
                }

                if(y === 0 && IsThird)
                {
                    //if(x === Math.floor(((tr.length-1)/2)))
                    //tr[x][y] = optimizeWith(tr[x][y]+"",titleCount);  
                    let colSpan = 0;
                    let width = 0;
                    if(refThird.current !== undefined)
                    {
                        let thirdWidthNow = thirdWidth;
                        if(thirdWidth === 0)
                        {
                            setThirdWidth(refThird.current.offsetWidth);
                            thirdWidthNow = refThird.current.offsetWidth;
                        }
                        while(width < thirdWidthNow)
                        {
                            width += refToUse[colSpan]
                            colSpan++;
                        }
                        //console.log(width +" " + colSpan)
                        tdAry.push(<React.Fragment key={y*tr[0].length+x}><th className='title is-6'  colSpan={/*titleCount*/colSpan} align="left" style={stickyAry[x] ? {"border": "2px solid black","position" : "sticky","left" : 0} : {"border": "2px solid black"}}><span className={tagClass} ref={refThird}>{tr[0][0]}</span></th></React.Fragment>);
                        stash = <></>;
                    }
                    else if(pageNav === 0)
                        stash = <div><table><thead><tr><th className='title is-6' align="left" style={stickyAry[x] ? {"border": "2px solid black","position" : "sticky","left" : 0} : {"border": "2px solid black"}}><span  ref={refThird} className={tagClass}>{tr[0][0]}</span></th></tr></thead></table></div>;
                    //else
                    //    tdAry.push(<React.Fragment key={y*tr[0].length+x}><td></td></React.Fragment>);
                    break;
                }
                else
                {  //////////////////////////////////////
                    tr[x][y] = tr[x][y]+"";               
                    //tr[x][y] = optimizeWith(tr[x][y]+"",1);  
                    
                    let match = /^[\d,.%-]+$/.test(tr[x][y].trim())
                    let columnsAry = [isAccountTitle ? titleColumns[4] : "",titleColumns[1],titleColumns[0],titleColumns[2],titleColumns[3]]
                    match = match && !columnsAry.includes(th[x]) && x >= titleCount //&& match === tr[x][y]
                    
                    if((y === tr[0].length-1) && (tabName !== "Catalogue Details"  || (tr[0][y] === "Total")))
                    {    
                        if(isNaN(th[x].split(" ")[0]) && x< th.length-3 && stickyAry[x])
                            lastRow.push(<React.Fragment key={y*tr[0].length+x}><th className={match ? "has-text-right" : "has-text-left"} style={{"background" : "white","zIndex" : 15,"position" : "sticky","left" :getPosLeft(refToUse,x-1,stickyAry)}}><span className={"title is-6"}>{tr[x][y]}</span></th></React.Fragment>);
                        else if(isNaN(th[x].split(" ")[0]) && stickyAry[x])
                            lastRow.push(<React.Fragment key={y*tr[0].length+x}><th className={match ? "has-text-right" : "has-text-left"} style={{"background" : "white","position" : "sticky","right" : getPosRight(refToUse,x+1,stickyAry) }}><span className={"title is-6"}>{tr[x][y]}</span></th></React.Fragment>);
                        else
                            lastRow.push(<React.Fragment key={y*tr[0].length+x}><th className={match ? "has-text-right" : "has-text-left"}><span className={"title is-6"}>{tr[x][y]}</span></th></React.Fragment>);
                    }
                    //else if(th[x] === "Total" || th[x] === "% Of Revenue")
                    //   tdAry.push(<React.Fragment key={y*tr[0].length+x}><td className={match ? "has-text-right" : "has-text-left"} style={{"position" : "sticky","left" : }}><span className={"title is-6"}>{tr[x][y]}</span></td></React.Fragment>);
                    else if(th[x] === "Cumulative %" && stickyAry[x])
                        tdAry.push(<React.Fragment key={y*tr[0].length+x}><td className={match ? "has-text-right" : "has-text-left"} style={{"background" : (y%2 === 0 ? `rgb(250, 250, 250)` : `white`),"position" : "sticky","right" : 0}}><span className={"title is-6"}>{tr[x][y]}</span></td></React.Fragment>);
                    else if(x === 0 && stickyAry[x] && outputName !== "Overview")
                    {
                        if(th[x] === "Song Name")
                            tdAry.push(<React.Fragment key={y*tr[0].length+x}><TdSpotifySong dataDir={props.dataDir} SpotyURL={getValue(props.spotiSongList,tr[x][y],"")} contant={tr[x][y]} y={y} isThird={y === 0 && IsThird} clientName={props.clientName}/></React.Fragment>);
                        else
                            tdAry.push(<React.Fragment key={y*tr[0].length+x}><td className={"has-text-left"} style={{"background" : (y%2 === 0 ? `rgb(250, 250, 250)` : `white`),"position" : "sticky","left" : 0}}><span className={tagClass}>{tr[x][y]}</span></td></React.Fragment>);
                    }
                    else if(isNaN(th[x].split(" ")[0]) && x< th.length-3 && stickyAry[x] && outputName !== "Overview")
                        tdAry.push(<React.Fragment key={y*tr[0].length+x}><td className={match ? "has-text-right" : "has-text-left"} style={{"background" : (y%2 === 0 ? `rgb(250, 250, 250)` : `white`),"position" : "sticky","left" :getPosLeft(refToUse,x-1,stickyAry)}}><span className={tagClass}>{tr[x][y]}</span></td></React.Fragment>);
                    else if(isNaN(th[x].split(" ")[0]) && stickyAry[x] && outputName !== "Overview")
                    {
                        if(th[x] === "Total")
                            // eslint-disable-next-line no-loop-func
                            tdAry.push(<React.Fragment key={y*tr[0].length+x}><TdPivot yearState={""} clientName={props.clientName} setPivotTable={setPivotTable} setTable={setTable} tagClass={tagClass} isTotal={{"position" : "sticky","right" : getPosRight(refToUse,x+1,stickyAry)}}  y={y} match={match} isPivot={isPivot} IsThird={y === 0 && IsThird} tdVal={tr[x][y]} dataDir={props.dataDir} titles={th.slice(0,titleCount)} values={tr.map((x) => x[y]).slice(0,titleCount)} jwt={jwt}/></React.Fragment>);
                        else
                            tdAry.push(<React.Fragment key={y*tr[0].length+x}><td className={(match ? "has-text-right" : "has-text-left")} style={{"background" : (y%2 === 0 ? `rgb(250, 250, 250)` : `white`),"position" : "sticky","right" : getPosRight(refToUse,x+1,stickyAry)}}><span className={tagClass}>{tr[x][y]}</span></td></React.Fragment>);
                    }
                    else if(!isNaN(th[x].split(" ")[0]))
                        // eslint-disable-next-line no-loop-func
                        tdAry.push(<React.Fragment key={y*tr[0].length+x}><TdPivot setPivotTable={setPivotTable} clientName={props.clientName} tagClass={tagClass} setTable={setTable} isTotal={{}} yearState={th[x]} y={y} match={match} isPivot={isPivot} IsThird={y === 0 && IsThird} tdVal={tr[x][y]} dataDir={props.dataDir} titles={th.slice(0,titleCount)} values={tr.map((x) => x[y]).slice(0,titleCount)} jwt={jwt}/></React.Fragment>);
                    //else if(th[x] == titleColumns[10])
                    //    tdAry.push(<React.Fragment key={y*tr[0].length+x}><td className={match ? "has-text-right" : "has-text-left"} style={{"background" : (y%2 == 0 ? `rgb(250, 250, 250)` : `white`)}}><span className={tagClass}>{tr[x][y].split(", ").map((val,index) => {return <React.Fragment key={index}><><span className='tag'>{val}</span>, </></React.Fragment>})}</span></td></React.Fragment>);
                    else
                        tdAry.push(<React.Fragment key={y*tr[0].length+x}><td className={match ? "has-text-right" : "has-text-left"} style={{"background" : (y%2 === 0 ? `rgb(250, 250, 250)` : `white`)}}><span className={tagClass}>{tr[x][y]}</span></td></React.Fragment>);

                }
            }
            trAry.push(<React.Fragment key={y}><tr>{tdAry}</tr></React.Fragment>);
        }
        let pageSplit = (10000/thAry.length);
        let paginglist = [];
        prevNav.current = [];
        paginglist = [];
       
        if(trAry.length > pageSplit)
        {
            let newAry = []
            for(let i=pageSplit;i<=trAry.length;i+= pageSplit)
            {
                if(i+pageSplit <= trAry.length)
                {
                    paginglist.push(<React.Fragment key={paginglist.length}><li><a className={i===pageSplit ? "pagination-link is-current" : "pagination-link"} ref={el => (prevNav.current = [...prevNav.current, el])} onClick={(e) => dispatch(setActionToDo("clickPage_"+e.currentTarget.innerText))} aria-label={"Goto page "+(paginglist.length+1)}>{paginglist.length+1}</a></li></React.Fragment>);   
                    newAry.push(trAry.slice(i-pageSplit,i))
                }
                else
                    newAry.push(trAry.slice(i-pageSplit,trAry.length))
            }
            paginglist.push(<React.Fragment key={paginglist.length}><li><a className={"pagination-link"} ref={el => (prevNav.current = [...prevNav.current, el])} onClick={(e) => dispatch(setActionToDo("clickPage_"+e.currentTarget.innerText))} aria-label={"Goto page "+(paginglist.length+1)}>{paginglist.length+1}</a></li></React.Fragment>);   
            trAry = newAry;
        }
        else
            trAry = [trAry];
            
        setPaginglist(paginglist);
        setTrAryReal(trAry);
        if(isOverview)
        {
            thAry = <React.Fragment key={thAry.length}>
                        <tr className='title is-5'>
                            <td className='has-text-centered' colSpan='999'>
                                {tr[0][0]}
                            </td>
                        </tr>
                        <tr>
                            {thAry}
                        </tr>
                    </React.Fragment>
        }
        setThAryReal(thAry);
        setlastRow(lastRow);
        setStash(stash);
        setCreateTable(true);
    }
    useEffect(()=>
    {
        dispatch(setGenerateTab(true));
        makeTable(true,stickyAry,props.jwt);
        setCreateTable(true);
        SetRenderTime(1);
    },[])
    
    useEffect(()=>
    {
        if(renderTime !== 0)
            props.setLoaderGap(renderTime)

        if(actionToDo.startsWith("clickPage"))
        {
            dispatch(setActionToDo(""));
            clickPage(actionToDo.split("_")[1]);
        }
        if(actionToDo.startsWith("pivot_"))
        {
            let ary = actionToDo.split("_");
            let ispivot = JSON.parse(ary[1])
            setIsPivot(ispivot);

            if(ispivot)
                dispatch(setActionToDo(""))
            else
                dispatch(setActionToDo("GetOutput"));
        }
        if(table == null || createTableVar)
        {
            if(trAryReal !== undefined && trAryReal[pageNav] !== undefined)
            {
                createTable();
                setCreateTable(false);
            }
        }
        else
        {
            if(renderTime !== 0)
            {
                props.setLoaderGap(!renderTime);
                SetRenderTime(renderTime-1);
                makeTable(false,stickyAry,props.jwt);
                setCreateTable(true);
                setTable(null);
                dispatch(setGenerateTab(false));
            }
        }
        if(actionToDo.startsWith("pivotActive"))
        {
            let ary = actionToDo.split("_");
            createTablePivot(ary[1],sortBy,ary[1] === "");
        }
    },[actionToDo,createTableVar,table,renderTime])
    return(<>
            {table}
            {trAryReal.length > 1 
            ?
                <nav className="pagination is-rounded" role="navigation" aria-label="pagination">
                    <a className={pageNav === 0 ? "pagination-previous is-disabled ":"pagination-previous"} onClick={(e) => {return(pageNav === 0 ? null : clickPage(pageNav))}}>Previous</a>
                    <a className={pageNav === trAryReal.length-1 ? "pagination-next is-disabled":"pagination-next"}  onClick={(e) =>  {return(pageNav === trAryReal.length-1 ? null: clickPage(pageNav+2))}}>Next page</a>
                    <ul className="pagination-list">
                        {pageList}
                    </ul>
                </nav>
            :
                <></>
            }
           </>);
}
export default MakeTable;