import React from 'react';
import ReactDOM from 'react-dom';
import {Provider ,useSelector} from "react-redux";
import store from "./redux/store";
import ContantComponent from './Components/ContantCom.js';
import { CookiesProvider } from "react-cookie";
import './index.scss';
import './index.css';
import 'bulma/css/bulma.min.css';

const MainPage = () =>
{
    const { frame } = useSelector((state) => state.frame) ;

    return  (
    <>
      {(frame === "Contant" ? <ContantComponent />: <></>)}
      <p className={frame !== "Contant"  ? 'subtitle is-5 has-text-white' : "subtitle is-5 has-text-black"} style={{"position" : "absolute","bottom" : "10px" ,"right" : "20px","zIndex" : 9999}}>© 2024 Bitsonic.ai</p>
    </>
   );
}

ReactDOM.render(
    <Provider store={store}>
        <CookiesProvider>
          <MainPage />
        </CookiesProvider>
    </Provider>,
  document.getElementById('root')
);
