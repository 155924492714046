/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */

import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from "react-redux";

import {  setCatalogDetails, setProgressBarValue, setTabMenuList } from '../redux/portal'
import { changeEmail, setAllUpTodate } from '../redux/frame'
import { setOutputTitle } from '../redux/customOutput';
import { setOgRename, setTabObjects } from '../redux/globalVars';

import ProgressBarCom from './ContantComponents/ProggressBarCom';
import CatalogMenuCom from './ContantComponents/CatalogMenuCom';
import MainContantCom from './ContantComponents/MainContantCom';
import NavBar from './ContantComponents/NavBarCom';

import pivotImage from '../Images/pivotImage.png'
import { ApiCall, GetDate, GetDateAndTime, Nodeurl, getDictFromTabObj, getListFromTabObj, getMulValues, getValue, pythonUrl } from '../Data/Variables';

const choosedclient = async (data,email,dispatch,SetComponentsVar,clientName,outputTitle,setOutputTitle,jwt,setCookie,version) =>
{
    data = JSON.parse(data);
    let icon = data["Logos"];
    let clientPacks = data["Packs"];
    let isAdmin = data["isAdmin"];
    let lastUpdate = new Date(0);
    let catalogStatus = data["catalogStatus"];
    data = data["result"];

    setInterval(async () => 
    {
        await fetch(pythonUrl + "/CheckVersion/"+((!clientName || clientName.trim() === "") ? undefined : clientName)+"/",
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then(resp => resp.json())
        .then(res => 
        {
            if(res["version"] !== version)
            { 
                alert("Updated data is available. The BitSonic Portal will now refresh itself.");
                window.location.reload(false);
            }
            //console.log(res)
            dispatch(setAllUpTodate(res.alloutput_up_to_date))
        })
        
    }, 30000)

    const idtoname = {}
    const cataDetailsObj = {}
    const catalogIdsAry = []
    const catalogMenuList = {}
    const filterValues = {}
    const currenciesValues = {}
    const currenciesKeys = {}
    const spotiSongList = {};
    let validCatalogs = {};
    //dispatch(setTheCatalog(data[0].title));
    for (let i = 0; i < data.length; i++) {
        let catalogName = data[i].title
        let currentStatus = data[i].projectStatus
        let cataStatus = catalogStatus[catalogName+"_"+data[i]["_id"]];
        if (currentStatus === undefined || currentStatus === null || currentStatus === "")
            currentStatus = "published"

            
        let count = Object.keys(cataDetailsObj).filter(x => x === catalogName || x.slice(0,x.indexOf("(")) === catalogName).length
        if(count !== 0)
            catalogName = catalogName + "("+count+")"
        if (true)//(adminEmails.includes(email))) 
        {
            cataDetailsObj[catalogName] = data[i];
            catalogIdsAry.push(data[i]["_id"]);
            idtoname[data[i]["_id"]] = catalogName;

            if(data[i]["hasFrame"] === undefined || (currentStatus !== "released" && currentStatus !== "in-progress"))
                data[i]["hasFrame"] = false
            
            let modified = new Date(parseInt(data[i].modified));
            if(modified > lastUpdate)
                lastUpdate = modified;

                catalogMenuList[catalogName] = { 
                "id" : data[i]["_id"],
                "since": GetDate(new Date(parseInt(data[i].setup))),
                "modified": GetDate(modified),
                "inProgress": (currentStatus === "in-progress" || !data[i]["hasFrame"]) && !isAdmin,
                "hasFrame" : data[i]["hasFrame"],
                "currentStatus" : currentStatus,
                //"totalLines" : getValue(cataDetailsObj[catalogName],"TotalLines",""),
                //"totalRev" :  getValue(cataDetailsObj[catalogName],"TotalRev",""),
                //"numOfSongs" : getValue(cataDetailsObj[catalogName],"NumOfSongs",""),
                "isAdmin" : isAdmin,
                "catalogStatus" : cataStatus,
                "UpdateSeen" : getValue(cataDetailsObj[catalogName],"UpdateSeen",true),

            }
            currenciesValues[catalogName] = {...data[i]["currency"],"usd" : 1};

            currenciesKeys[catalogName] = getValue(data[i],"outputCurrency","").toUpperCase();
        }
    }
    let menuTabList = {};
    let params = 
    {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : jwt  },
        body: new URLSearchParams(
            {
                "catalogList": catalogIdsAry,
                "userPath": email,
                "clientName" : clientName
            })
    }
    let res = await ApiCall(pythonUrl + "/GetOldOutputs",params,setCookie)
    res = await res.json();
    dispatch(setAllUpTodate(res.alloutput_up_to_date));
    let hasAllOutput = res.hasAllOutput;
    if(hasAllOutput)
    {
        catalogIdsAry.push("");
        idtoname[""] = "";
        cataDetailsObj[""] = {"_id" : ""}
        currenciesValues[""] = {"usd" : 1};
        currenciesKeys[""] = "USD";
    }
    let masterOriginRename = {}
    console.log(res);
    let tab_objects = [];
    for (let i = 0; i < res.tab_objects.length; i++) 
    {
        const obj = {
            name : res.tab_objects[i].special_nickname,
            folder : res.tab_objects[i].special_folder,
            defualt_output : res.tab_objects[i].defualt_output,
            packs : res.tab_objects[i].packs,
            is_draft_format : res.tab_objects[i].is_draft_format,
            index : res.tab_objects[i].index,
            is_cashdate : res.tab_objects[i].is_cashdate,
        };

        if(res.tab_objects[i].is_draft_format)
        {
            obj["namedraft"] = res.tab_objects[i].special_nickname+" Draft";
            obj["globalnamedraft"] = "Global "+res.tab_objects[i].special_nickname+" Draft";
    
            obj["folderdraft"] = res.tab_objects[i].special_folder+"_Draft";
            obj["globalfolderdraft"] = res.tab_objects[i].special_folder+"_GlobalDraft";
        }
        tab_objects.push(obj)
    }
    let tabNameTotabName = getDictFromTabObj(tab_objects, ["name","namedraft","globalnamedraft"],["name","namedraft","globalnamedraft"]);
    let defualttaboutput = getDictFromTabObj(tab_objects,["name","namedraft","globalnamedraft"],["defualt_output"])
    defualttaboutput[""] = "Song x Rev x Period";

    let tabtofolder = getDictFromTabObj(tab_objects,["name","namedraft","globalnamedraft"],["folder"]);
    tabtofolder[""] = "ALL";

    let tabtofolderdraft = getDictFromTabObj(tab_objects,["name","namedraft","globalnamedraft"],["folder","folderdraft","globalfolderdraft"])
    let packstofolder = res.packstofolder;
    let ordertabs = getListFromTabObj(tab_objects,["folder","folderdraft","globalfolderdraft"]);
    ordertabs.sort((a,b) => a.index-b.index)
    ordertabs.splice(1, 1, "All Output");
    ordertabs.splice(2, 1, "Catalog All Output");
    console.log({tab_objects,defualttaboutput,tabtofolder,tabtofolderdraft,packstofolder,ordertabs})
    dispatch(setTabObjects({tab_objects,defualttaboutput,tabtofolder,tabtofolderdraft,packstofolder,ordertabs}));
    for (let i = 0; i < catalogIdsAry.length; i++) 
    {
        masterOriginRename[idtoname[catalogIdsAry[i]]] = JSON.parse(JSON.stringify(tabNameTotabName));
        filterValues[idtoname[catalogIdsAry[i]]] = res[catalogIdsAry[i]].filterValues
        if(filterValues[idtoname[catalogIdsAry[i]]] === "NoFilter")
        {
            catalogMenuList[idtoname[catalogIdsAry[i]]]["inProgress"] = true
            filterValues[idtoname[catalogIdsAry[i]]] = [];
        }
        else if(idtoname[catalogIdsAry[i]] === "" || (cataDetailsObj[idtoname[catalogIdsAry[i]]].hasFrame && !cataDetailsObj[idtoname[catalogIdsAry[i]]]["inProgress"]))
            validCatalogs[idtoname[catalogIdsAry[i]]] = catalogIdsAry[i];

        menuTabList[idtoname[catalogIdsAry[i]]] = {}
        menuTabList[idtoname[catalogIdsAry[i]]] = res[catalogIdsAry[i]].data;
        spotiSongList[idtoname[catalogIdsAry[i]]] = getValue(res[catalogIdsAry[i]],"spotiSongList",{});
        let ary = 
        [
            getMulValues(menuTabList,[idtoname[catalogIdsAry[i]],"standard","details","standard"],          undefined),
            getMulValues(menuTabList,[idtoname[catalogIdsAry[i]],"distribution","details","distribution"],      undefined),
            getMulValues(menuTabList,[idtoname[catalogIdsAry[i]],"cashdate","details","cashdate"],          undefined),

            getMulValues(menuTabList,[idtoname[catalogIdsAry[i]],"standard_Draft","details","standard_Draft"],    undefined),
            getMulValues(menuTabList,[idtoname[catalogIdsAry[i]],"distribution_Draft","details","distribution_Draft"],undefined),
            getMulValues(menuTabList,[idtoname[catalogIdsAry[i]],"cashdate_Draft","details","cashdate_Draft"],    undefined),

            getMulValues(menuTabList,[idtoname[catalogIdsAry[i]],"standard_GlobalDraft","details","standard_GlobalDraft"],    undefined),
            getMulValues(menuTabList,[idtoname[catalogIdsAry[i]],"distribution_GlobalDraft","details","distribution_GlobalDraft"],undefined),
            getMulValues(menuTabList,[idtoname[catalogIdsAry[i]],"cashdate_GlobalDraft","details","cashdate_GlobalDraft"],    undefined)
        ]
        let aryTitles = ["Standard Outputs","Standard Outputs By Cash Date","Cash Date Outputs"
                        ,"Standard Outputs Draft","Standard Outputs By Cash Date Draft","Cash Date Outputs Draft"
                        ,"Global Standard Outputs Draft","Global Standard Outputs By Cash Date Draft","Global Cash Date Outputs Draft",
                    ]
        for(let j=0;j<ary.length;j++)
        {
            //console.log(i);
            if(ary[j] !== undefined)
            {
                delete masterOriginRename[idtoname[catalogIdsAry[i]]][aryTitles[j]]
                masterOriginRename[idtoname[catalogIdsAry[i]]][ary[j]] = aryTitles[j];
            }
        }
    }
    if(hasAllOutput)
    {
        menuTabList[""] = res[""].data;
        filterValues[""] = res[""].filterValues;
        masterOriginRename[""] = {"Overview" : "Overview", "All Output" : "","Catalog All Output" : ""}
    }
    console.log(cataDetailsObj)
    console.log(masterOriginRename)
    console.log(tabNameTotabName);
    console.log(menuTabList);
    if(Object.keys(masterOriginRename).some(val => val !== '' && Object.keys(masterOriginRename[val]).length !== Object.keys(tabNameTotabName).length))
    {
        console.log(Object.keys(masterOriginRename).filter(val => val !== '' && Object.keys(masterOriginRename[val]).length !== Object.keys(tabNameTotabName).length))
        throw Error("error, please contact data@bitsonic.ai")
    }
    dispatch(setProgressBarValue(50));
    dispatch(setOutputTitle({...outputTitle,"currencies" : currenciesValues,"currencyKey" : currenciesKeys}));
    dispatch(setCatalogDetails(cataDetailsObj));
    dispatch(setOgRename(masterOriginRename));
    dispatch(setTabMenuList(menuTabList));
    dispatch(setProgressBarValue(100));
    SetComponentsVar({
        "ProgressBar": <ProgressBarCom />,
        "CatalogMenu": <CatalogMenuCom catalogMenuList={catalogMenuList} jwt={jwt} hasAllOutput={hasAllOutput} isAdmin={isAdmin}/>,
        "MainContant": <MainContantCom clientName={clientName} spotiSongList={spotiSongList} validCatalogs={validCatalogs} catalogStatus={catalogStatus}
                                catalogMenuList={catalogMenuList} filterValues={filterValues} packs={clientPacks} jwt={jwt}/>,
        "NavBar" : <NavBar icon={icon} lastUpdate={GetDateAndTime(lastUpdate)} packlens={clientPacks.length} jwt={jwt} clientName={clientName}/>
    })   
   
}
const AccountButton = (props) =>
{
    const { outputTitle} = useSelector((state) => state.customOutput);
    const { version } = useSelector((state) => state.frame);

    return (<>
        <button className='button is-large is-info mb-2' style={{"width":"100%"}} onClick={() => {choosedclient(props.data,props.email,props.dispatch,props.SetComponentsVar,props.accountName,outputTitle,setOutputTitle,props.jwt,props.setCookie,version);props.setModalClients("")}}>
            <span className="icon is-large"><i className="fa-solid fa-user" /></span>
            <span>{props.accountName}</span>
        </button><br/>
            </>);
}
const CatalogContant = () => {
    const dispatch = useDispatch();
    const { actionToDo ,theCatalog} = useSelector((state) => state.portal);
    const { outputTitle} = useSelector((state) => state.customOutput);
    const { version } = useSelector((state) => state.frame);

    const [ComponentsVar, SetComponentsVar] = useState({});
    const [clientsComponent, setClientsComponent] = useState(<></>);
    const [modalClients, setModalClients] = useState("");
    const [pivotAnimate, setPivotAnimate] = useState(false);
    const [pivotAnimateWas, setPivotAnimateWas] = useState(false);
    const [cookies, setCookie, ] = useCookies();
    useEffect(() => 
    {
        const getCatalog = async () =>
        {
            let param = 
            {
                method: "post",
                headers: { 'Content-Type': 'application/json',"Authorization" : cookies.jwt  },
            };
            let data = await ApiCall(Nodeurl + "/GetCatalogs",param,setCookie)

            data = await data.json();

            dispatch(changeEmail(data.email));
            let email = data.email;
            data = data.data;
            
            let dataKeys = Object.keys(data);
            let dataLen = dataKeys.length
            if(dataLen === 0)
                alert("email doesnt exist")
            else if(dataLen > 1)
            {
                setModalClients("is-active");
                let ary = [];
                for(let i=0;i<dataLen;i++)
                {
                    ary.push(
                        <React.Fragment key={i}>
                            <AccountButton data={data[dataKeys[i]]} accountName={dataKeys[i]} dispatch={dispatch} setModalClients={setModalClients} SetComponentsVar={SetComponentsVar} jwt={ cookies.jwt} setCookie={setCookie} email={email}/>
                        </React.Fragment>)
                }
                ary.sort((a, b) => {return (dataKeys[ary.indexOf(a)].localeCompare(dataKeys[ary.indexOf(b)]))});

                setClientsComponent(ary);
            }
            else
                choosedclient(data[dataKeys[0]],email,dispatch,SetComponentsVar,dataKeys[0],outputTitle,setOutputTitle,cookies.jwt,setCookie,version);
        
        }
        getCatalog();

    }, [])

    useEffect(() => 
    {
        setPivotAnimateWas(false);
    }, [theCatalog])
    
    useEffect(() =>
    {
        if(actionToDo.startsWith("pivot_"))
        {
            let ary = actionToDo.split("_");
            if(JSON.parse(ary[1]) && !pivotAnimateWas)
            {
                setPivotAnimate(true);
                setPivotAnimateWas(true);
            }
        }
    },[actionToDo]);

    return (<div className='fullHeightPage' onClick={() => {return ((pivotAnimate ? setPivotAnimate(false) : null))}}>
                <div className='navBarHeight' >
                    {pivotAnimate
                    ?   <>
                            <div className='divImagePivot' style={{"width":"60vh","height" : "40vh"}}><img className='fitt' src={pivotAnimate ? pivotImage : null} width="60%" alt="pivotImage"></img></div> 
                            <div className='darkenDiv'/>
                        </>
                    : 
                        <></>}
                    <div className={"modal "+modalClients}>
                        <div className="modal-background"></div>
                        <div className="modal-card"  style={{"width" : "40%"}}>
                            <section className="modal-card-body">
                                <center>
                                    <h1 className='title'>You have multiple portal accounts with this login.</h1>
                                    <h1 className='subtitle'>Choose one account:</h1>
                                    {clientsComponent}
                                </center>
                            </section>
                        </div>
                    </div>
                    {ComponentsVar["ProgressBar"]}
                    <div>{ComponentsVar["NavBar"]}</div>
                    <div className='block fullHeight'>
                        {Object.keys(ComponentsVar).length === 0 
                            ?
                                <center><div className='loaderCss mt-6' ></div></center>
                            :
                                <div className='columns fitt is-marginless is-variable is-1	'>
                                    <div className='column is-2'>
                                        <div className='fullHeight' style={{"overflowY" : "scroll"}}>
                                            {ComponentsVar["CatalogMenu"]}
                                        </div>
                                    </div>
                                    <div className='column is-10'>
                                        <div className='fullHeight'>
                                            {ComponentsVar["MainContant"]}
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>);
}
export default CatalogContant;
