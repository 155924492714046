import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { setActionToDo, setTabMenuList } from "../../../redux/portal";
import { setButtonOkLoad } from "../../../redux/modals";
import { ApiCall,  HTTP_200_OK, PDF_FILE, SORTED_DATA, TABLE_FILES, getValue, pythonUrl, repoTab } from "../../../Data/Variables";

function CsvDisplayComponent({data}) {
    const columns = Object.keys(data);

    return (
        <>
            <div className="table-container box p-0 m-3" style={{"height":"92%","width" : "96%","overflow":"scroll"}}>
                <table className="table is-hoverable is-scrollable is-gapless is-striped is-narrow has-sticky-header">
                <thead>
                    <tr>
                    {columns.map((column, index) => (
                        <th key={index}>{column}</th>
                    ))}
                    </tr>
                </thead>
                <tbody>
                    {data[columns[0]].map((_, rowIndex) => (
                    <tr key={rowIndex}>
                        {columns.map((column, colIndex) => (
                        <td key={colIndex}>{data[column][rowIndex]}</td>
                        ))}
                    </tr>
                    ))}
                </tbody>
                </table>
            </div>
        </>
    );
  };


const AryOfComps = ({menuIndex,setMenuIndex,jwt,setFileViewer,setRepoDownloadText}) =>
{
    let count = 0;
    const {tabMenuList,theCatalog} = useSelector((state) => state.portal);
    const [openedList,setOpenedList] = useState(new Set([]));
    let dicti = getValue(tabMenuList[theCatalog],SORTED_DATA,[]);
    const recorse = (dicti,path,isActive,isOpened) =>
    {
        let FileComps = [];
        
        for(const [key, value] of Object.entries(dicti))
        {
            if(value.name)
            {
                if(key.substring(0,key.lastIndexOf('.')).trim() !== "" && isOpened)
                    FileComps.push(<React.Fragment key={++count}><RepoFileComp setRepoDownloadText={setRepoDownloadText} i={count} isActive={isActive} 
                                                                               filename={key} filedetails={value} menuIndex={menuIndex} setMenuIndex={setMenuIndex}
                                                                               jwt={jwt} path={path} setFileViewer={setFileViewer}/></React.Fragment>);
            }
            else if(isOpened)
                FileComps.push(
                    <React.Fragment key={++count}>
                        <li >
                            <React.Fragment key={++count}>
                                <RepoFolderComp i={count} setRepoDownloadText={setRepoDownloadText} openedList={openedList} 
                                                isOpened={!openedList.has(count) && isOpened} setOpenedList={setOpenedList} 
                                                isActive={isActive} path={path} jwt={jwt} foldername={key} menuIndex={menuIndex} setMenuIndex={setMenuIndex}/>

                            </React.Fragment>
                            <ul className="menu-list mr-0">
                                {recorse(dicti[key],[...path,key],count-1 === menuIndex || isActive, !openedList.has(count-1) && isOpened)}
                            </ul>
                        </li>
                    </React.Fragment>
                );
        }
        return FileComps;
    }
    return recorse(dicti,[],false,true);
}
const FileDetails = ({fileext,filedetails}) =>
{

    return (
        <center>
            <span className="tag is-info is-light mr-4">File size:<b className="ml-1">{filedetails.size.toFixed(4)} MB</b></span>
            <span className="tag is-primary is-light">{fileext === PDF_FILE ? "Page" : "Line"} count:<b className="ml-1">{filedetails.line_count}</b></span>
        </center>
    )
}
export const RepoFileComp = ({i,filename,isActive,menuIndex,setRepoDownloadText,setMenuIndex,jwt,path,setFileViewer, filedetails}) =>
{
    const [, setCookies, ] = useCookies();
    const dispatch = useDispatch();
    const { theCatalog,catalogDetails,tabMenuList,actionToDo,theOutput} = useSelector((state) => state.portal);
    const { buttonOkLoad } = useSelector((state) => state.modals);

    const [,setFile] = useState({});
    const [isEdit,setIsEdit] = useState(false);
    const [currFilename,setCurrFilename] = useState(filedetails.name.substring(0,filedetails.name.lastIndexOf('.')));
    let fileext = filename.substring(filename.lastIndexOf('.')+1).toLowerCase()
    const output = theOutput.split("_")
    const fetchFile = async () =>
    {
        setFileViewer(<center><div className='loaderCss' ></div></center>)
        let params = 
        {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : jwt},
            body: new URLSearchParams({'file_path' : JSON.stringify([...path, filename]),catalog_id : catalogDetails[theCatalog]["_id"]})
        }
        let data = await ApiCall(pythonUrl+"/FetchSortedDataFile",params,setCookies,(err) => {alert(err);});
        if(data.status === HTTP_200_OK)
        {
            if(fileext === PDF_FILE)
            {
                data = await data.blob();
                data = new Blob([data], { type: 'application/pdf' });
                //data.type = "application/pdf"
                const url = URL.createObjectURL(data);
                setFile({URL : url,File : data})
                setFileViewer(<>
                                <FileDetails filedetails={filedetails} fileext={fileext}/>
                                {/*eslint-disable-next-line jsx-a11y/iframe-has-title*/}
                                <iframe src={url} height="100%" width={"100%"}></iframe>
                              </>)
            }
            else if(TABLE_FILES.includes(fileext))
            {
                data = await data.json();
                setFileViewer(<>
                                <FileDetails filedetails={filedetails} fileext={fileext}/>
                                <CsvDisplayComponent data={data}/>
                              </>)
            }
        }
        else
        {
            data = await data.text();
            setFileViewer(<div style={{
                                        'height' : "80%",
                                        'display': 'flex',
                                        'justifyContent': 'center', 
                                        'alignItems': 'center', 
                                    }}>
                            <center>
                                <h1 className="title is-5"><b>{data}</b></h1>
                            </center>
                          </div>)
        }
    }
    useEffect(() =>
    {
        if(actionToDo === "DownloadOutput" && output[1] === repoTab)
        {
            dispatch(setActionToDo(""));
            const downloadData = async () =>
            {
                dispatch(setButtonOkLoad({...buttonOkLoad, "Download" : true}));
                let params = 
                {
                    method: 'POST',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : jwt},
                    body: new URLSearchParams({'file_path' : JSON.stringify([...path,filename]),
                                                catalog_id : catalogDetails[theCatalog]["_id"]})
                }
                let data = await ApiCall(pythonUrl+"/DownloadSortedDataFile",params,setCookies,(err) => {alert(err);});
                if(data.status === HTTP_200_OK)
                {
                    const blob = await data.blob();
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.setAttribute("download", filedetails.name);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
                dispatch(setButtonOkLoad({...buttonOkLoad, "Download" : false}));
            }
            if(i === menuIndex)
                downloadData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[actionToDo])
    if(i === menuIndex)
        setRepoDownloadText("Download this file")
    
    return <li>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a className={i === menuIndex || isActive ? "is-active" : ""} onClick={(e) => {if(!isEdit) {setMenuIndex(i);fetchFile()} }}>
                    <div className="columns is-vcentered">
                        
                        <div className="coulmn is-1"  onClick={() => {setMenuIndex(i);fetchFile()}}><span className="icon">{TABLE_FILES.includes(fileext) ? <i className="fa-solid fa-table"></i> : (fileext === PDF_FILE ? <i className="fa-solid fa-book"></i> : null)}</span></div>
                        <div className="column pr-0 canEated">
                            {isEdit ? <input className="input" value={currFilename} onFocus={(e) => e.stopPropagation()} onChange={(e) => setCurrFilename(e.currentTarget.value)}
                             onBlur={async (e) => 
                                {
                                    const currfilenameext = currFilename+"."+fileext;
                                    let params = 
                                    {
                                        method: 'POST',
                                        headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : jwt},
                                        body: new URLSearchParams(
                                        {
                                            'file_path' : JSON.stringify([...path,filename]),
                                            catalog_id : catalogDetails[theCatalog]["_id"],
                                            "newFileName" : currfilenameext
                                        })
                                    }
                                    let data = await ApiCall(pythonUrl+"/RenameSortedDataFile",params,setCookies,(err) => {alert(err);});
                                    if(data.status === HTTP_200_OK)
                                    {
                                        data = await data.json()
                                        setCurrFilename( data.filename.substring(0,data.filename.lastIndexOf('.')))
                                        dispatch(setTabMenuList({...tabMenuList,[theCatalog] : {...tabMenuList[theCatalog],[SORTED_DATA] : data.data}}));
                                        setIsEdit(false);
                                    }
                                }} autoFocus={isEdit}/> : <div className="canEated"><span>{currFilename}</span></div>}
                        </div>
                        <div className="column is-narrow"><button className="button is-warning is-small is-rounded  is-pulled-right" onClick={(e) => {e.stopPropagation();setIsEdit(true)}} ><span className="icon"><i className="fa-solid fa-file-pen"/></span></button></div>
                        
                    </div>
                </a>
            </li>;
}
export const RepoFolderComp = ({i,isActive,path,setRepoDownloadText,jwt,foldername,menuIndex,setMenuIndex,openedList,setOpenedList,isOpened}) =>
{
    const [, setCookies, ] = useCookies();
    const dispatch = useDispatch();
    const { theCatalog,catalogDetails,actionToDo,theOutput} = useSelector((state) => state.portal);
    const { buttonOkLoad } = useSelector((state) => state.modals);
    const output = theOutput.split("_")

    useEffect(() =>
    {
        if(actionToDo === "DownloadOutput" && output[1] === repoTab)
        {
            dispatch(setActionToDo(""));
            const downloadData = async () =>
            {
                dispatch(setButtonOkLoad({...buttonOkLoad, "Download" : true}));
                let params = 
                {
                    method: 'POST',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : jwt},
                    body: new URLSearchParams({'file_path' : JSON.stringify([...path,foldername]),
                                                catalog_id : catalogDetails[theCatalog]["_id"]})
                }
                let data = await ApiCall(pythonUrl+"/DownloadSortedDataFile",params,setCookies,(err) => {alert(err);});
                if(data.status === HTTP_200_OK)
                {
                    const blob = await data.blob();
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.setAttribute("download", foldername+".zip");
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
                dispatch(setButtonOkLoad({...buttonOkLoad, "Download" : false}));
            }
            if(i === menuIndex)
                downloadData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[actionToDo])
    
    if(i === menuIndex)
        setRepoDownloadText("Download this folder")
    return (<>
                <div className="columns p-0 is-vcentered">
                    <div className="column is-narrow p-0">
                        <span className="icon" style={{"cursor" : "pointer"}} onClick={() => 
                        {
                            if(openedList.has(i))
                            {
                                let openedset = new Set([...openedList]);
                                openedset.delete(i);
                                setOpenedList(openedset)
                            }
                            else
                                setOpenedList(new Set([...openedList,i]));
    
                        }}>
                            {isOpened ? <i class="fa-solid fa-caret-down"/> :  <i class="fa-solid fa-caret-right"/>}
                        </span>
                    </div>
                    <div className="column p-0">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                        <a className={i === menuIndex || isActive ? "is-active p-0 pb-1 mt-2" : "p-0 mt-2"} onClick={(e) => setMenuIndex(i)}>
                            <span className="tag is-medium ml-1 mt-1"><p className="menu-label">{foldername}</p></span>
                        </a>
                    </div>
                </div>
            </>);
}

export const RepoDataComp = ({jwt,setRepoDownloadText}) =>
{
    const [menuIndex,setMenuIndex] = useState(-1);
    const [fileViewer,setFileViewer] = useState();
    const { actionToDo} = useSelector((state) => state.portal);
    const [startWidth,setStartWidth] = useState();
    const [startX,setStartX] = useState();
    const [isResize,setIsResize] = useState(false);
    const colFilesRef = useRef();
    const dispatch = useDispatch();
    
    useEffect(() =>
    {
        if(actionToDo === "DownloadOutput" && menuIndex === -1)
        {
            alert("Choose File/Folder");
            dispatch(setActionToDo(""));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[actionToDo])
    return (
        <div className="columns fullHeight "
            onMouseMove={(e)=> {if(isResize) colFilesRef.current.style.width = startWidth + e.clientX - startX + 'px';}}
            onMouseUp={(e) => setIsResize(false)}
            onMouseLeave={(e) => setIsResize(false)}
        >
            <div className="column is-5 scrollvert pt-4 pl-5" style={{"overflowY" : "scroll","overflowX" : "hidden"}}  ref={colFilesRef}
                onMouseDown={(e) => setMenuIndex(-1)}
            >
                <aside className="menu ">
                    <ul className="menu-list">
                    {isResize ? <></> 
                        :
                        <AryOfComps menuIndex={menuIndex} setMenuIndex={setMenuIndex} jwt={jwt} setFileViewer={setFileViewer} setRepoDownloadText={setRepoDownloadText}/>
                          }
                    </ul>
                </aside>
            </div>
            <div className="column is-narrow is-divider-vertical p-2" style={{"cursor" : "col-resize" }} 
                onMouseDown={(e) => 
                {
                    setIsResize(true);
                    setStartX(e.clientX);
                    setStartWidth(colFilesRef.current.offsetWidth);
                }}
            />
            <div className="column ml-0 pr-0 pl-0" style={{"overflowY" : "hidden","overflowX" : "hidden"}}>
                {isResize ? <></> :  fileViewer}
            </div>
        </div>

    );
}